<template>
  <ContainerBox>
    <div class="container-box-title d-flex">Editais do concurso</div>
    <div class="d-flex flex-column" v-for="ed in editais" :key="ed.id">
      <div class="d-flex flex-row pointer">
        <div @click="expnd(ed)">
          <v-icon  v-text="ed.expand ? 'mdi-chevron-down' : 'mdi-chevron-right'" />
          <span class="title">Edital {{ ed.title }}</span>
        </div>
        <a v-if="ed.expand" :href="ed.url" target="_blank" class="ml-2" title="Expandir"><v-icon v-text="'mdi-arrow-expand'" /></a>
      </div>
      <iframe v-if="ed.expand" :src="ed.url" class="h-full minh" />
    </div>
  </ContainerBox>
</template>

<script>
  import ContainerBox from '../components/ContainerBox.vue';

  export default {
    name: 'EditalProva',
    components: {
      ContainerBox,
    },
    data: () => ({
      editais: [{
        id: 1,
        url: "https://concursoapi.pge.se.gov.br/OfficialDocuments/Edital-001.2023.pdf",
        title: "001/2023",
        expand: false
      },{
        id: 2,
        url: "https://concursoapi.pge.se.gov.br/OfficialDocuments/Edital-002.2023.pdf",
        title: "002/2023",
        expand: false
      },{
        id: 3,
        url: "https://concursoapi.pge.se.gov.br/OfficialDocuments/Edital-003.2023.pdf",
        title: "003/2023",
        expand: false
      },{
        id: 4,
        url: "https://concursoapi.pge.se.gov.br/OfficialDocuments/Edital-004.2023.pdf",
        title: "004/2023",
        expand: false
      },{
        id: 5,
        url: "https://concursoapi.pge.se.gov.br/OfficialDocuments/Edital-005.2023.pdf",
        title: "005/2023",
        expand: true
      }],
    }),
    methods: {
      expnd(edital) {
        this.editais.map(e => e.id !== edital.id ? e.expand = false : true);
        edital.expand = !edital.expand;
      },
    },
    computed: {
    },
  }
</script>

<style>
  .minh {
    min-height: 60vh;
    margin-bottom: 8px;
    margin-left: 24px;
  }
</style>
