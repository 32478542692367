<script>
export default {
  props:
    ['concurso']
  ,
  data() {
    return {
      date: '',
      currentTab: 0,
      validFormValues: true,
      documentTypes:[
        {'name':"Edital"}, 
        {'name':"Sala de prova"},
        {'name': "Caderno de provas", 'hasDate': true},
         {'name': "Gabarito", 'hasDate': true}],
      documents: [],
      newDocument: {
        name: "",
        file: null,
        fileFormat: ""
      },
      // Pra saber porque ter desse rules2: 
      // https://rmirabelle.medium.com/fixing-vuetifys-form-validation-3a5781ea43fe
      rules2: {
        required: value => !!value || "Campo obrigatório"
      },
      rules: {}
    }
  },
  computed: {
    currentType() {
      return this.currentTab + 1
    },
    filteredDocuments() {
      return this.documents.filter(doc => doc.type === this.currentType)
    }
  },
  methods: {
    isContestFinished() {
      if (!this.concurso) return

      if (this.date == '') {
        this.date = new Date()
      }

      var datePartFinal = this.concurso.contestFinalDate.split('/')
      var dateConvertedFinal = new Date(
        datePartFinal[2], 
        datePartFinal[1] - 1, 
        datePartFinal[0]
      )
      dateConvertedFinal.setHours(23, 59, 59, 59)
      return this.date > dateConvertedFinal
    },
    openFileUpload() {
      this.$refs.fileInput[this.currentTab].click()
    },
    clickToAddFile() {
      const input = document.querySelector('input.file-input')
      input.click()
    },
    getFileFormatFromFilename(filename) {
      // const splittedDocFilename = filename.split(".")
      const docFormat = filename.substring(filename.lastIndexOf('.')+1)
      // const docFormat = splittedDocFilename[splittedDocFilename.length - 1]

      this.newDocument.fileFormat = docFormat
    },
    addFileByInput(e) {
      this.newDocument.file = e.target.files[0]

      this.getFileFormatFromFilename(this.newDocument.file.name)

      console.log(this.newDocument)
    },
    addFileByDrop(e) {
      this.newDocument.file = e.dataTransfer.files[0]
      
      this.getFileFormatFromFilename(this.newDocument.file.name)
    },
    addNewDocument() {
      this.rules = this.rules2
      this.$refs.form[this.currentTab].validate()
      if (this.validFormValues) {
      const file = this.newDocument.file
      const splittedDocFilename = file.name.substring(0, file.name.lastIndexOf('.'))
      const docFormat = file.name.substring(file.name.lastIndexOf('.')+1)
      if(this.newDocument.name == '')
        this.newDocument.name = splittedDocFilename
      
      const docNewName = `${this.newDocument.name}.${docFormat}`

      const fileBlob = file.slice(0, file.size, file.type)
      const newFile = new File([fileBlob], docNewName, {
        type: file.type
      })

      this.documents.push({
        ...this.newDocument,
        type: this.currentType,
        fileFormat: docFormat,
        file: newFile
      })

      this.newDocument.file = null
      this.newDocument.name = ""
      this.newDocument.type = null

      this.$emit("documents", this.documents)
      }
    },
    removeDocument(name) {
      this.documents = this.documents.filter(doc => doc.name != name)
      this.$emit("documents", this.documents)
    },
  }
}
</script>

<!-- eslint-disable vue/valid-v-for -->
<template>
  <v-card flat color="neutral">
    <v-tabs v-model="currentTab" background-color="neutral">
      <v-tabs-slider color="transparent"></v-tabs-slider>
      <v-tab
        v-for="(type, index) in documentTypes" 
        :key="index"
        :disabled="type.hasDate ? (isContestFinished() ? false : true ) : false"
        active-class="active-tab"
      >
        {{ type.name }}
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="currentTab">
      <v-tab-item v-for="type in documentTypes" class="neutral">
        <v-card flat class="flex flex-wrap flex-md-nowrap" color="neutral">
          <section class="new-doc-section">
            <v-card-title>{{ type.name }}</v-card-title>
            <v-card-text>
              <v-form ref="form" v-model="validFormValues" lazy-validation>
                <fieldset>
                  <label class="font-semibold" for="documentName">
                    Nome do documento:
                  </label>
                  <v-text-field
                    id="documentName" 
                    solo flat dense
                    color="white"
                    background-color="white"
                    v-model="newDocument.name"
                  />
                </fieldset>

                <fieldset v-cloak @drop.prevent="addFileByDrop" @dragover.prevent>
                  <label class="font-semibold" for="document">
                    Documento:
                  </label>
                  <input 
                    ref="fileInput" 
                    type="file" 
                    accept=".pdf,.docx,image/*"
                    class="hidden-file-upload-input"
                    @change="addFileByInput" 
                  />
                  <v-input
                    type="file"
                    show-size
                    background-color="white"
                    class="w-full"
                    :value="newDocument.file"
                    :rules="[rules.required]"
                    @click="() => openFileUpload()"
                  >
                    <div class="file-upload-input">
                      <v-icon 
                        class="w-full" 
                        size="150px" 
                        color="light-blue lighten-3"
                      >
                        mdi-note-plus
                      </v-icon>
                      <p 
                        class="font-bold text-md-body-1 light-blue--text text--lighten-3"
                      >
                        Arraste ou clique para adicionar um arquivo
                      </p>
                    </div>
                  </v-input>
                  <v-list :class="!newDocument.file 
                    ? 'transparent mt-2' : 
                    'added-documents-item rounded-md white mt-2'
                  ">
                    <v-list-item>
                      <v-list-item-icon>
                        <template v-if="!!newDocument.file && newDocument.fileFormat === 'pdf'">
                          <v-icon color="red">mdi-file-pdf-box</v-icon>
                        </template>
                        <template v-if="!!newDocument.file && newDocument.fileFormat === 'docx'">
                          <v-icon color="blue">mdi-file-document</v-icon>
                        </template>
                      </v-list-item-icon>
                      <v-list-item-content v-if="!!newDocument.file">
                        {{ newDocument.file.name }}
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </fieldset>
                <v-btn 
                  elevation="0" 
                  color="primary"
                  @click="addNewDocument" 
                  class="w-full mt-5"
                  large
                >
                  <v-icon class="mr-2">mdi-note-plus</v-icon>
                  Salvar Documento
                </v-btn>
              </v-form>

            </v-card-text>
          </section>

          <section class="added-docs-section">
            <v-card-title>Documentos Adicionados</v-card-title>
            <v-card-text>
              <strong v-if="filteredDocuments.length == 0" class="text-body-1">
                Nenhum documento adicionado
              </strong>
              <v-list v-if="filteredDocuments.length != 0" color="transparent">
                <v-list-item 
                  v-for="(document, index) in filteredDocuments" 
                  class="added-documents-item rounded-md white mt-2"
                  :key="index"
                >
                  <v-list-item-icon>
                    <template v-if="document.fileFormat === 'pdf' || document.fileFormat === 'PDF'">
                      <v-icon color="red">mdi-file-pdf-box</v-icon>
                    </template>
                    <template v-if="document.fileFormat === 'docx'">
                      <v-icon color="blue">mdi-file-document</v-icon>
                    </template>
                  </v-list-item-icon>
                  <v-list-item-content>
                    {{ document.name }}
                  </v-list-item-content>
                  <v-list-item-action @click="() => removeDocument(document.name)">
                    <v-icon>mdi-close</v-icon>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </v-card-text>
          </section>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<style scoped>

.new-doc-section {
  width: 100%;
  height: 100%;
}

.added-docs-section {
  width: 100%;
}
.tab-item-bg{
  background-color: #F5F2F2;
}

.active-tab {
  color: white;
  background-color: #1570B2 !important;
  border-radius: 15px;
}

.hidden-file-upload-input {
  display: none;
}

.file-upload-input {
  width: 100%;
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.eita {
  height: 200px;
}

.input-file {
  opacity: 0;
  width: 100%;
  height: 200px;
  position: absolute;
  cursor: pointer;
}
</style>