<!-- eslint-disable vue/require-v-for-key -->
<template>
    <div>
        <ol v-if="list.length > 0">
            <li class="break-words" v-bind="doc" v-for="doc in list">
                <a :href="doc.path.replace('wwwroot/', url)" target="_blank">{{ doc.name }}</a>
            </li>
        </ol>
        <span class=" font-bold text-center" v-else>Ainda não foram disponibilizados</span>
    </div>
</template>

<script>
    import baseURL from '../services/baseUrl'
    export default{
        props: ['list'],
        data: () =>({ 
            url: baseURL
        })
    }
</script>