<template>
  <ContainerBox>
    <div class="container-box-title">Local de Prova</div>
    <div class="mapouter">
      <div class="gmap_canvas border-2 border-gray-500">
        <iframe class="w-full" height="600" id="gmap_canvas" src="https://maps.google.com/maps?q=49035-050,%20numero%201190&t=&z=13&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
      </div>
    </div>
  </ContainerBox>
</template>

<script>
  import ContainerBox from '../components/ContainerBox.vue';

  export default {
    name: 'LocalProva',
    components: {
      ContainerBox,
    },
  }
</script>
