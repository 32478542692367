<script>
import ContainerBox from "@/components/ContainerBox.vue"

import CustomButton from '@/components/CustomButton.vue';
  
import { getContests } from '@/services/Service'

export default {
  components: {
    ContainerBox,
    // eslint-disable-next-line vue/no-unused-components
    CustomButton,
  },
  data() {
    return {
      search: "",
      feedback: {
        show: false,
        text: "",
        type: ""
      },
      loading: true,
      allContests: []
    }
  },
  computed: {
    contests() {
      if (this.allContests == [])
        return []
      
      if (this.search == "")
        return this.allContests
      
      return this.allContests.filter(contest => {
        
        return contest.name.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "").match(this.search.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, ""))
      })
    } 
  },
  methods: {
    goToContestSignUp() {
      this.$router.push("/concurso/new")
    },
    goToContest(contestId) {
      this.$router.push(`/concurso/${contestId}`)
    },
    goToContestInscriptions(contestId) {
      this.$router.push(`/restrictedArea/${contestId}`)
    }
  },
  async mounted() {
    const contests = await getContests()

    this.allContests = contests
    this.loading = false
  }
}
</script>

<template>
  <ContainerBox>
    <h1 class="text-4xl font-semibold mb-8">Gerenciar Concursos PGE/SE</h1>

    <section 
      class="d-flex justify-content-between align-center flex-wrap flex-sm-nowrap"
    >
      <v-text-field 
        solo flat 
        hide-details
        background-color="#f5f2f2"
        placeholder="Pesquisar"
        v-model="search" 
        append-icon="mdi-magnify"
        class="search-bar mr-4 flex-grow"
      />
      <v-btn 
        color="primary" 
        large 
        elevation="0" 
        class="mt-4 mt-sm-0 flex-sm-grow text-body-1"
        @click="goToContestSignUp"
      >
        <v-icon class="mr-4">mdi-note-plus</v-icon>
        Cadastrar Concurso
      </v-btn>
    </section>

    <hr class="my-4 grey lighten-2"/>

    <section>
      <p class="primary--text text-xl font-weight-bold">{{ 
        this.contests.length > 0 
        ? `${this.contests.length} resultados`
        : `${this.contests.length} resultado` 
      }}</p>
      
      <div class="d-flex flex-wrap">
        <v-card
          v-for="contest in contests" 
          :key="contest.id" 
          class="contest-card rounded-lg lrounded mr-3 my-3"
          elevation="0"
          width="450"
        >
          <v-card-title class="text-2xl font-semibold">
            {{ contest.name }}
          </v-card-title>
          <v-card-text>
            <p class="text-lg black--text">
              Inscrições até {{ contest.inscriptionsFinalDate }}
            </p>
          </v-card-text>
          <v-card-actions class="ml-4 pr-4 pb-2">
            <v-btn 
              elevation="0" 
              color="primary" 
              @click="() => goToContest(contest.id)"
            >
              <v-icon class="mr-2">mdi-plus-circle</v-icon>
              Mais informações
            </v-btn>

            <v-btn 
              elevation="0" 
              color="positive" 
              @click="() => goToContestInscriptions(contest.id)"
            >
              <v-icon class="mr-2" color="white">mdi-cog</v-icon>
              <span class="white--text">Gerenciar</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </div>
    </section>
  </ContainerBox>
</template>

<style>
.search-bar {
  width: 100%;
  max-width: 30em;
}

.contest-card {
  border-left: 8px solid #1570B2 !important;
  background-color: #f5f2f2;
}

.new-contest-button {
  width: 100%;
  max-width: 11em;
}
</style>