<script>
import ContainerBox from '@/components/ContainerBox.vue'
import ContestDocumentForm from '@/components/ContestDocumentForm.vue'
import baseURL from '@/services/baseUrl'
import axios from "../services/axiosBase"

export default {
  components: {
    ContainerBox,
    ContestDocumentForm
  },
  data() {
    return {
      url: baseURL,
      valid: true,
      feedback: {
        show: false,
        text: "",
        type: "positive"
      },
      rules: {
        required: value => !!value || "Obrigatório",
        year: value => String(value).length == 4 || "Ano inválido",
        cep: value => value.match(/\d{5}-\d{3}/) || "CEP inválido",
        edition: value => value.length <= 3 || "Edição inválida (até 3 caractéres)"
      },
      newContest: {
        title: "",
        year: "",
        edition: "",
        totalVacancies: 0,
        startDate: null,
        endDate: null,
        subscriptionStartDate: null,
        local: "",
        street: "",
        inscriptionValue: 0,
        district: "",
        city: "",
        number: "",
        cep: "",
        googleAddressLink: "",
        documents: [],
      }
    }
  },
  methods: {
    async registerNewContest() {
      this.$refs.form.validate()

      if (!this.valid) {
        this.feedback.show = true 
        this.feedback.text = "Um ou mais campos possuem erros."
        this.feedback.type = "negative"

        window.scrollTo({ top: true, behavior: 'smooth' })
      } else {
        const formData = new FormData()

        formData.set("Name", this.newContest.title)
        formData.set("Edition", this.newContest.edition)
        formData.set("Year", this.newContest.year)
        formData.set("ContestVacancies", this.newContest.totalVacancies)
        formData.set("ContestStartDate", this.newContest.startDate)
        formData.set("ContestFinalDate", this.newContest.endDate)
        formData.set("InscriptionsFinalDate", this.newContest.subscriptionFinalDate)
        formData.set("InscriptionValue", this.newContest.inscriptionValue)
        formData.set("Local", this.newContest.local)
        formData.set("CEP", this.newContest.cep)
        formData.set("District", this.newContest.district)
        formData.set("City", this.newContest.city)
        formData.set("Street", this.newContest.street)
        formData.set("Number", this.newContest.number)
        formData.set("GoogleAdressLink", this.newContest.googleAddressLink)

        const documents = this.newContest.documents.map(doc => {
          return {
            Document: doc.file,
            DocumentType: doc.type
          }
        })

        documents.forEach((doc, index) => {
          formData.append(`DocumentContest[${index}].Document`, doc.Document)
          formData.append(`DocumentContest[${index}].DocumentType`, doc.DocumentType)
        })

        const url = `${this.url}Contest/CreateContest`

        const resp = await axios.post(url, formData).catch((error) => {
          this.$swal('Erro', 'Ocorreu um erro ao cadastrar o concurso', 'error')
          console.log(error)
        })
        if(resp.status == 200){
            this.$swal('Sucesso', 'Concurso cadastrado!', 'success')
            this.documents = []
            this.$router.push({path: "/admin/concursos"})
        }
      }
    },
    onAddedFileFromDocumentForms(documents) {
      this.newContest.documents = documents
    },
    cancelForm() {
      this.$router.push("/admin/concursos")
    }
  }
}
</script>

<template>
  <ContainerBox class="container">
    <h1 class="text-4xl font-semibold">Cadastrar Concurso</h1>

    <v-form class="w-full mt-12" ref="form" v-model="valid">
      <h2 class="text-2xl mb-4 font-semibold primary--text">Dados gerais</h2>
      
      <fieldset class="flex flex-wrap flex-md-nowrap">
        <fieldset class="w-full">
          <label class="font-semibold" for="titulo-concurso">
            Título do concurso:
          </label>
          <v-text-field 
            outlined 
            v-model="newContest.title" 
            :rules="[rules.required]"
          />
        </fieldset>
      </fieldset>
      <section class="flex flex-wrap flex-md-unwrap justify-content-between">
        <fieldset class="flex">
          <span class="w-full mr-4">
            <label class="font-semibold" for="ano-concurso">Ano:</label>
            <v-text-field 
              outlined 
              v-model="newContest.year" 
              type="number"
              :rules="[rules.required, rules.year]"
            />
          </span>
      
          <span class="w-full mr-md-4">
            <label class="font-semibold" for="edicao-concurso">
              Edição:
            </label>
            <v-text-field 
              outlined 
              v-model="newContest.edition" 
              counter="3"
              :rules="[rules.required, rules.edition]"
            />
          </span>

        </fieldset>
        <fieldset class="flex">
          <span class="w-full mr-4">
            <label class="font-semibold" for="vacancies-concurso">
              Nº de vagas:
            </label>
            <v-text-field 
              outlined 
              type="number"
              v-model="newContest.totalVacancies" 
              :rules="[rules.required]"
            />
          </span>
          <span class="w-full">
            <label class="font-semibold" for="inscription-value-concurso">
              Valor de inscrição:
            </label>
            <v-text-field 
              outlined
              type="text"
              inputmode="numeric"
              v-model="newContest.inscriptionValue"
              :rules="[rules.required]"
            />
          </span>
        </fieldset>
      </section>

       <fieldset class="flex flex-wrap flex-md-nowrap justify-content-between">
        <span class="w-full mr-md-4">
          <label class="font-semibold" for="data-inicio-concurso">
            Data de início do concurso:
          </label>
          <v-text-field 
            type="date" 
            outlined 
            v-model="newContest.startDate" 
            :rules="[rules.required]"
          />
        </span>
      
        <span class="w-full mr-md-4">
          <label class="font-semibold" for="data-fim-concurso">
            Data de fim do concurso:
          </label>
          <v-text-field 
            type="date" 
            outlined 
            v-model="newContest.endDate" 
              :rules="[rules.required]"
          />
        </span>
      
        <span class="w-full">
          <label class="font-semibold" for="data-inicio-inscricoes-concurso">
            Data de fim das inscrições:
          </label>
          <v-text-field
            type="date"
            outlined 
            v-model="newContest.subscriptionFinalDate"
            :rules="[rules.required]"
          />
        </span>
      </fieldset>

      <fieldset>
        <h2 class="text-2xl font-semibold mb-4 primary--text">
          Endereço do concurso
        </h2>
        <fieldset class="d-flex flex-wrap flex-lg-nowrap justify-content-between ">
          <span class="mr-4">
            <label for="cep-concurso-address" class="font-semibold">
              CEP:
            </label>
            <v-text-field 
              outlined 
              v-model="newContest.cep"
              type="text"
              maxlength="9"
              placeholder="Ex. 12345-678"
              :rules="[rules.required, rules.cep]" 
            />
          </span>
          <fieldset class="flex flex-row">
            <span class="flex-grow w-full mr-4">
              <label for="street-concurso-address" class="font-semibold">
                Rua:
              </label>
              <v-text-field 
                outlined 
                v-model="newContest.street" 
                :rules="[rules.required]"
              />
            </span>
            <span>
              <label for="number-concurso-address" class="font-semibold">
                Número:
              </label>
              <v-text-field 
                outlined
                type="number"
                v-model="newContest.number" 
                :rules="[rules.required]"
              />
            </span>
          </fieldset>
        </fieldset>

        <fieldset class="d-flex flex-wrap flex-md-nowrap justify-content-between">
          <span class="mr-md-4 w-full">
            <label for="local-concurso-address" class="font-semibold">
              Local:
            </label>
            <v-text-field 
              :rules="[rules.required]" 
              outlined 
              v-model="newContest.local"
            />
          </span>
          <div class="flex w-full">
            <span class="mr-4 w-full">
              <label for="city-concurso-address" class="font-semibold">
                Cidade:
              </label>
              <v-text-field 
                :rules="[rules.required]" 
                outlined 
                v-model="newContest.city"
              />
            </span>
            <span class="flex-grow-3 w-full">
              <label for="district-concurso-address" class="font-semibold">
                Bairro:
              </label>
              <v-text-field 
                :rules="[rules.required]" 
                outlined 
                v-model="newContest.district"
              />
            </span>
          </div>
        </fieldset>
        <fieldset>
          <label for="googleaddress-concurso" class="font-semibold">
            Link do endereço do Google:
          </label>
          <v-text-field 
            :rules="[rules.required]" 
            outlined 
            type="url"
            v-model="newContest.googleAddressLink"
          />
        </fieldset>
      </fieldset>

      <ContestDocumentForm v-on:documents="onAddedFileFromDocumentForms" />

      <section 
        class="flex flex-wrap flex-md-nowrap justify-content-center mx-auto my-4"
      >
        <v-btn 
          large
          elevation="0"
          class="my-2 md:my-0 form-action-button mr-md-2" 
          color="error" 
          @click="cancelForm"
        >
          Cancelar
        </v-btn>
        <v-btn 
          large
          elevation="0"
          class="my-2 md:my-0 form-action-button ml-md-2" 
          @click="registerNewContest" 
          color="primary"
        >
          Cadastrar
        </v-btn>
      </section>
    </v-form>

    <v-snackbar 
      bottom
      v-model="feedback.show" 
      :color="feedback.type === 'positive' ? 'green' : 'red'"
      class="d-flex align-center"
    >
      <span class="text-body-1">{{ feedback.text }}</span>

      <template v-slot:action="{ attrs }">
        <v-btn icon v-bind="attrs" @click="feedback.show = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </ContainerBox>
</template>

<style scoped>
.form-action-button {
  width: 100%;
  max-width: 500px;
}
</style>