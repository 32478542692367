import Vue from 'vue';
import store from '../store/authModule';
import VueRouter from 'vue-router';
import Cadastro from '../views/Cadastro.vue';
import ValidarInscricao from '../views/ValidarInscricao.vue';
import Edital from '../views/Edital.vue';
import Home from '../views/HomeView.vue';
import LocalProva from '../views/LocalProva.vue';
import NotFound from '../views/NotFound.vue';
import AlocacaoSalas from '../views/AlocacaoSalas';
import ProvaGabarito from '../views/ProvaGabarito';
import Andamentos from '../views/Concursos/Andamentos.vue';
import Encerrados from '../views/Concursos/Encerrados.vue'
import Login from '../views/Login.vue';
import MinhasInscricoes from '../views/MinhasInscricoes.vue';
import Concurso from '../views/Concurso.vue'
import CadastroConcurso from '../views/CadastroConcurso.vue'
import AdminConcursos from '../views/AdminConcursos.vue'
import AnexarDocumentos from '../views/AnexarDocumentos.vue'
import RecuperarSenha from '../views/RecuperarSenha.vue'
import AlterarSenha from '../views/AlterarSenha.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/cadastro',
    name: 'Cadastro',
    component: Cadastro
  },
  {
    path: '/prova',
    name: 'ProvaGabarito',
    component: ProvaGabarito
  },
  {
    path: '/restrictedArea/:code',
    name: 'ValidarInscricao',
    component: ValidarInscricao,
    meta: {requiresPrivilege: true},
  },
  {
    path: '/anexarDocumento/:code',
    name: 'AnexarDocumentos',
    component: AnexarDocumentos,
    // meta: {requiresPrivilege: true},
  },
  {
    path: '/',
    name: 'HomePage',
    component: Home
  },
  {
    path: '/edital',
    name: 'EditalProva',
    component: Edital
  },
  {
    path: '/localprova',
    name: 'LocalProva',
    component: LocalProva
  },
  {
    path: '/salas',
    name: 'AlocacaoSalas',
    component: AlocacaoSalas
  },
  {
    path: '/minhasInscricoes',
    name: 'MinhasInscricoes',
    component: MinhasInscricoes,
    meta: {requiresAuth: true},
  },
  {
    path: '*',
    name: 'NotFound',
    component: NotFound
  },
  {
    path: "/concurso/new",
    name: "CadastroConcurso",
    component: CadastroConcurso,
    // meta: {requiresPrivilege: true}
  },
  {
    path: "/admin/concursos",
    name: "AdminConcursos",
    component: AdminConcursos,
    // meta: {requiresPrivilege: true}
  },
  {
    path:'/concurso/:code',
    name: 'Concurso',
    component: Concurso
  },
  {
    path:'/alterarSenha',
    name: 'AlterarSenha',
    component: AlterarSenha
  },
  {
    path:'/recuperarSenha/',
    name: 'RecuperarSenha',
    component: RecuperarSenha
  },
  {
    path: '/andamento',
    name: 'ConcursosAndamentos',
    component: Andamentos
  },
  {
    path: '/encerrados',
    name: 'ConcursosEncerrados',
    component: Encerrados
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  if(to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.isLoggedIn) {
      next()
      return
    }
    next('/')
  }
  else if(to.matched.some(record => record.meta.requiresPrivilege)) {
    if (store.getters.isLoggedIn && store.getters.role) {
      next()
      return
    }
    next('/')
  } else {
    next()
  }
})

export default router;
