import App from './App.vue';
import router from './router';
import store from './store/authModule';
import Vue from 'vue';
import vuetify from './plugins/vuetify';
import VueMask from 'v-mask';
import VueSweetalert2 from "vue-sweetalert2";
import './Styles/input.css';
import "sweetalert2/dist/sweetalert2.min.css";
import * as api from './services/Service';

Vue.use(VueMask);
Vue.config.productionTip = false;
Vue.use(VueSweetalert2);

Vue.prototype.$api = api;

new Vue({
  store,
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
