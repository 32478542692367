<!-- eslint-disable vue/valid-v-for -->
<template>
    <ContainerBox>
        <div id="Panel" class="d-flex flex-column align-items-start flex-grow">
            <div class="container-box-title">Minhas inscrições</div>
            <div class="w-full h-[100vh] flex flex-wrap gap-5 items-start sm:justify-center">
                <v-card outlined class="contest-card lrounded rounded-lg mr-3 my-3" v-for="(card,index) in concursos">
                    <v-card-title class="text-2xl font-semibold">{{card.contestName}}</v-card-title>
                    <v-card-text class="text-xl font-bold select-none"> Status: <span class="p-2 drop-shadow-2xl text-white font-bold shadow text-base	 rounded" :style="`background-color: ${filterColor(card.status)}`" > {{ filterName(card.status) }}</span></v-card-text>
                    <v-card-actions class="ml-4 pr-4 pb-4 m-auto gap-2 sm:gap-1 lg:flex-col"> 
                        <div  @click="$router.push({path: `/concurso/${card.id}` })">
                        <CustomButton color="#1570B2" icon="mdi-plus-circle" texto="Mais informações" />
                        </div>
                        <div v-if="!isAdmin" @click="indexSelected = index" class="flex flex-wrap gap-2 lg:flex-col lg:items-center">
                            <div @click="card.modalCancel = true" v-if="card.status == 'Pagamento_Pendente'&& isEnrollOpen(card.inscriptionFinalDate)">
                                <CustomButton  color="#A52017" icon="mdi-pen-off" texto="Cancelar Inscrição" />
                            </div>
                            <div @click="card.modalAttach = true, indexSelected = index, inscriptionCard = card.inscriptionNumber" v-if="(card.status == 'Pagamento_Pendente' || card.status == 'Recusada') && isEnrollOpen(card.inscriptionFinalDate)">
                                <CustomButton  color="#17A536" icon="mdi-paperclip-plus" texto="Anexar Comprovante" />
                            </div>
                        </div>
                        <Modal title="Deseja cancelar a sua inscrição neste concurso?" type="warning" width="900" @disable-Modal="card.modalCancel = false" :dialog="card.modalCancel" v-model="card.modalCancel">
                            <template v-slot:botoes>
                            <div @click="card.modalCancel = false">
                                <CustomButton color="#7D7F80" icon="mdi-arrow-left" texto="Voltar" />
                            </div>
                            <div @click="card.modalCancel = false, cancelInscription(card.id, card)">
                                <CustomButton color="#17A536" icon="mdi-pen-off" texto="Confirmar" />
                            </div>
                        </template>
                        </Modal>
                        <div @click="$router.push({path: '/restrictedArea'})" v-if="isAdmin">
                        <CustomButton color="#17A536" icon="mdi-cog" texto="Gerenciar" />
                        </div>
                    </v-card-actions>
                    <Modal title="Anexar Comprovante" type="confirmation" width="750" @disable-Modal="card.modalAttach = false" :dialog="card.modalAttach" v-model="card.modalAttach">
                        <template v-slot:texto>
                            <div class="flex flex-col items-center gap-4">
                            <img src="../assets/Qrcode.png" class=" w-1/2 mt-4" alt="qrcode">
                            <span>Nome: PROCURADORIA GERAL DO ESTADO </span>
                            <v-btn @click="copyQRCode()" color="primary" class="flex gap-2">
                                Copiar código do QR Code
                                <v-icon>
                                mdi-content-copy
                                </v-icon>
                            </v-btn>
                            <span v-if="isCopy" class=" text-positive">O código foi copiado!</span>
                            <div class="w-2/4 h-32 flex flex-col">
                                <v-file-input
                                :rules="[validateFile(attachment)]"
                                v-model="attachment"
                                id='fileInput'
                                append-icon="mdi-paperclip"
                                hint="Formatos permitidos: .pdf, .png, .jpg"
                                loader-height
                                clearable
                                filled 
                                show-size
                                prepend-icon=""
                                @click:append="openFilePicker"
                                @click:clear="clearAttachment"
                                label="Anexar comprovante">
                                </v-file-input>
                            </div>
                            </div>
                        </template>
                        <template v-slot:botoes>
                            <div @click="desactiveModal(card)">
                                <CustomButton color="#7D7F80" icon="mdi-clock-outline" texto="Deixar para depois" />
                            </div>
                            <div :class="validateAttachment(attachment) ? '' : 'pointer-events-none'" @click="card.modalAttach = false, insertPaymentProof(indexSelected,attachment, inscriptionCard)">
                                <CustomButton :color="validateAttachment(attachment) ? '#17A536': '#86BD92'" icon="mdi-send-variant-outline" :isdisabled="false" texto="Enviar comprovante" />
                            </div>
                        </template>
                    </Modal>
                </v-card>
            </div>
        </div>
    </ContainerBox>
</template>

<script>
    import ContainerBox from '@/components/ContainerBox.vue';
    import CustomButton from '@/components/CustomButton.vue';
    import Modal from '@/components/Modal.vue';
    export default{
        components: {
            ContainerBox,
            CustomButton,
            Modal
        },
        data: () =>({
            date: '',
            inscriptionCard: '',
            indexSelected: '',
            isCopy: false,
            modalCancel: false,
            modalAttach: false,
            idUser: '',
            attachment: '',
            qrcode: '00020126820014br.gov.bcb.pix01364ceea742-e33b-4ba3-8694-b2dfcea5b88f0220Concurso Estagiarios520400005303986540520.005802BR5925PROCURADORIA GERAL DO EST6007ARACAJU62290525zTggJ2tJuVm8KELmMjwZqetWx6304C420',
            concursos: [],
            isAdmin: ''
        }),
        methods: {
            desactiveModal(card){
                card.modalAttach = false
                this.attachment = ''
            },
            openFilePicker() {
                document.getElementById('fileInput').click()
            },
            filterName(value){
                if(value == 'Pagamento_em_Analise') return 'Pagamento em Análise'
                else{
                    return value.replace("_", " ")
                }
            },
            filterColor(value){
                if(value == 'Pagamento_Pendente') return '#E0B532'
                if(value == 'Pagamento_em_Analise') return '#2660A4'
                if(value == 'Confirmada') return '#17A536'
                if(value == 'Cancelada') return '#FF5252'
                if(value ==  'Recusada') return '#EB3838'
            },
            clearAttachment(){
                this.attachment = new File()
            },
            copyQRCode(){
                navigator.clipboard.writeText(this.qrcode)
                this.isCopy = true
                setTimeout( () => {
                    this.isCopy = false
                }, 3000)
                
            },
            isEnrollOpen(inscriptionFDate){
                var datePart = inscriptionFDate.split('/')
                var dateConverted = new Date(datePart[2], datePart[1] - 1, datePart[0])
                dateConverted.setHours(23, 59, 59, 59)
                return this.date <= dateConverted
            },
            async cancelInscription(idCard, card){
                await this.$api.CancelInscription(idCard, this.idUser).then( (response) => {
                    if(response.status == 204){
                        this.$swal('Sucesso', `Inscrição foi cancelada`,'success')
                        card.status = "Cancelada"
                    }
                    else{
                        this.$swal('Erro', `Ocorreu um erro ao cancelar a inscrição`,'error')
                    }
                })
            },
            async loadCards(){
                var auxResponse = await this.$api.MyInscriptions(this.idUser).then((response) => {
                return response.data
                })
                for(var j = 0; j < auxResponse.length; j++){
                    console.log(auxResponse[j])
                    const aux = {
                    id: auxResponse[j].contestId,
                    contestName: auxResponse[j].contestName,
                    inscriptionFinalDate: auxResponse[j].inscriptionFinalDate,
                    inscriptionNumber: auxResponse[j].inscriptionNumber,
                    status: auxResponse[j].status,
                    modalCancel: false,
                    modalAttach: false
                    };
                    this.concursos.push(aux)
                }
            },
            async showSwal(){
                await this.$swal('Sucesso', `Comprovante enviado com sucesso, você poderá acompanhar o status da sua inscrição na aba "Minhas inscrições"`,'success')
                window.location.reload()
            },
            async insertPaymentProof(indexSelected , attachment, inscriptionNumber){
                await this.$api.InsertPaymentProof(inscriptionNumber, attachment).then((response) => {
                    if(response.status == 200){
                        this.concursos[indexSelected].InscriptionStatus = 'Pagamento_em_Analise'
                        this.showSwal()
                        
                    }
                    else{
                    this.$swal('Erro', 'Ocorreu um erro ao enviar o comprovante', 'error')
                    }
                }).catch(console.error)
                attachment = ''
            },
            validateAttachment: (v) => (v.type === 'application/pdf' || v.type === "image/png" || v.type === "image/jpeg" ) && v != '' && v != null,
            validateFile: (v) => (v.type === 'application/pdf' || v.type === "image/png" || v.type === "image/jpeg") || "Somente arquivos com formato pdf, png ou jpeg são permitidos",
        },
        beforeMount() {
            this.isAdmin = this.$store.getters.role
            this.idUser = this.$store.getters.getId
        },
        mounted(){
            this.date = new Date()
            this.loadCards()
        }

    }
</script>

<style>
    .shadow{
        text-shadow: 0.4px 0.4px black;
    }
</style>