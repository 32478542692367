<!-- eslint-disable vue/multi-word-component-names -->
<template>
    <ContainerBox>
        <div class="container-box-title">Concursos Encerrados</div>
        <div class="w-full h-[100vh] flex flex-wrap gap-5 items-start">
            <span class="font-bold text-2xl" v-if="concursos.length == 0">Não há nenhum concurso encerrado no momento </span>
            <v-card :key="card.id" width="450" v-for="card in concursos" outlined class="contest-card lrounded rounded-lg mr-3 my-3" >
                <v-card-title class="text-2xl font-semibold">{{card.name}}</v-card-title>
                <v-card-actions class="ml-4 pr-4 pb-4 gap-2 m-auto sm:gap-1 sm:flex-col"> 
                    <div @click="$router.push({path: `/concurso/${card.id}` })">
                    <CustomButton color="#1570B2" icon="mdi-plus-circle" texto="Mais informações" />
                    </div>
                    <div @click="$router.push({path: `/restrictedArea/${card.id}`})" v-if="isAdmin">
                    <CustomButton color="#17A536" icon="mdi-cog" texto="Gerenciar" />
                    </div>
                </v-card-actions>
            </v-card>
        </div>
    </ContainerBox>
</template>

<script>
    import ContainerBox from '@/components/ContainerBox.vue';
    import CustomButton from '@/components/CustomButton.vue';
    export default {
        components: {
            ContainerBox,
            CustomButton
        },
        data: () => ({
            concursos: [],
            isAdmin: false
        }),
        mounted() {
            this.loadContests()
            this.isAdmin = this.$store.getters.role
        },
        methods:{
            async loadContests() {
                var response = await this.$api.getContestsDisabled().catch(console.error);
                this.concursos = response.data
            }
        }
    }
</script>