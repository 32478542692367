<template>
    <ContainerBox>
        <h1 class="text-4xl font-bold">
                {{ concurso.name }}
            </h1>
        <div class="container-box-title">
            Lista de Inscrições {{getStatus}}
        </div>
        <div class="flex justify-end">
            <v-btn 
            color="primary" 
            large 
            elevation="0" 
            class="mt-4 mt-sm-0 flex-sm-grow text-body-1"
            @click="$router.push({path: `/anexarDocumento/${idConcurso}`})"
            >
                <v-icon v-icon class="mr-4">mdi-file-plus</v-icon>
                Editar Concurso
            </v-btn>
        </div>
        
        <div class="d-flex flex-wrap justify-content-center mt-1 mb-1">
            <v-toolbar height="62" class="d-flex h-4 flex-wrap overflow-y-scroll justify-content-center mt-1  mb-1 text-center" dense elevation="4">
                <ul class="d-flex plnone">
                    <li :class="['libtn m-auto select-none', 'px-6 py-2', !['xs', 'sm'].includes($vuetify.breakpoint.name) ? 'hover:bg-gray-100  mx-4' : '', btnStatus == 4 ? 'text-primary' : 'text-dark']" @click="btnStatus = 4, loadUsers()"><b>Inscrições Canceladas</b></li>
                    <li :class="['libtn m-auto select-none', 'px-6 py-2', !['xs', 'sm'].includes($vuetify.breakpoint.name) ? 'hover:bg-gray-100  mx-4' : '', btnStatus == 1 ? 'text-primary' : 'text-dark']" @click="btnStatus = 1, loadUsers()"><b>Inscrições Pendentes</b></li>
                    <li :class="['libtn m-auto select-none', 'px-6 py-2', !['xs', 'sm'].includes($vuetify.breakpoint.name) ? 'hover:bg-gray-100  mx-4' : '', btnStatus == 2 ? 'text-primary' : 'text-dark']" @click="btnStatus = 2, loadUsers()"><b>Inscrições Em Análise</b></li>
                    <li :class="['libtn m-auto select-none', 'px-6 py-2', !['xs', 'sm'].includes($vuetify.breakpoint.name) ? 'hover:bg-gray-100  mx-4' : '', btnStatus == 3 ? 'text-primary' : 'text-dark']" @click="btnStatus = 3, loadUsers()"><b>Inscrições Confirmadas</b></li>
                    <li :class="['libtn m-auto select-none', 'px-6 py-2', !['xs', 'sm'].includes($vuetify.breakpoint.name) ? 'hover:bg-gray-100  mx-4' : '', btnStatus == 5 ? 'text-primary' : 'text-dark']" @click="btnStatus = 5, loadUsers()"><b>Inscrições Recusadas</b></li>
                    <!-- <li :class="['libtn m-auto', 'px-6 py-2', !['xs', 'sm'].includes($vuetify.breakpoint.name) ? 'hover:bg-gray-100  mx-4' : '']" @click="verifyHash = true"><b v-if="!['xs', 'sm'].includes($vuetify.breakpoint.name)">Verificar Código de Inscrição</b><b v-else><v-icon v-text="'mdi-shield-check'" /></b></li> -->
                </ul>
            </v-toolbar>
        </div>
        <!-- <v-dialog max-width="750" v-model="verifyHash">
            <v-card class="square">
                <v-card-title style="word-break: break-word;">
                    Digite o código da inscrição para ser verificado.
                </v-card-title>
                <v-card-text>
                    <v-text-field
                        solo
                        :append-icon="'mdi-magnify'"
                        type="text"
                        @click:append="searchCode()"
                        :rules="[required]"
                        outlined
                        hide-details="auto"
                        v-model="codeToSearch"
                        @keyup.enter="searchCode()"
                    ></v-text-field>
                </v-card-text>
            </v-card>
        </v-dialog> -->
        <div>
            <span v-if="filteredUsers.length > 0" class="d-flex justify-content-center title mt-4">
                {{ filteredUsers.length.toLocaleString('en', { minimumIntegerDigits: 2 }).split(",").join(".") }}
                {{ filteredUsers.length > 1 ? `Inscrições ${getStatus}` : `Inscrição ${getStatus.replace(/s$/, "")}` }}
            </span>
            <div class="mt-5 justify-content-center px-6">
                <v-text-field
                    solo
                    outlined
                    label="Pesquisar CPF"
                    hide-details="auto"
                    v-model="searchFilter"
                ></v-text-field>
            </div>
            <div class="mt-6 px-6">
                <table class="w-full text-sm text-gray-500 dark:text-gray-400" v-if="filteredUsers.length > 0">
                    <thead class="text-sm text-left text-gray-700 uppercase bg-gray-200 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                            <th scope="col" class="px-4 py-3 col-2">
                                CPF
                            </th>
                            <th v-if="!['xs', 'sm'].includes($vuetify.breakpoint.name)" scope="col" class="px-4 py-3 col-4">
                                Nome
                            </th>
                            <th v-if="!['xs', 'sm'].includes($vuetify.breakpoint.name)" scope="col" class="px-4 py-3 col-5">
                                E-mail
                            </th>
                            <th v-if="!['xs', 'sm'].includes($vuetify.breakpoint.name)" scope="col" class="px-4 py-3 col-5">
                                Etnia
                            </th>
                            <th v-if="[1,2,3,5].includes(btnStatus)" scope="col" class="px-4 py-3 col-1 text-center">
                                Ações
                            </th>
                        </tr>
                    </thead>
                    <tbody v-for="user in filteredUsers" :key="user.cpf">
                        <tr class="bg-gray-50 border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600">
                            <td class="px-4 py-3">
                                {{user.cpf}}
                            </td>
                            <td v-if="!['xs', 'sm'].includes($vuetify.breakpoint.name)" class="px-4 py-3">
                                {{user.name}}
                            </td>
                            <td v-if="!['xs', 'sm'].includes($vuetify.breakpoint.name)" class="px-4 py-3">
                                {{user.email}}
                            </td>
                            <td v-if="!['xs', 'sm'].includes($vuetify.breakpoint.name)" class="px-2 py-3">
                                {{user.ethnicity}}
                            </td>
                            <td v-if="[1,2,3,5].includes(btnStatus)" class="px-4 py-3 text-center">
                                <div class="flex" v-if="btnStatus == 5">
                                    <v-btn icon color="primary" @click="checkFile(user)"><v-icon>mdi-file-document-outline</v-icon></v-btn>
                                    <v-btn icon color="success" @click="validateUser(user, 1)"><v-icon>mdi-check</v-icon></v-btn>
                                </div>
                                <div class="flex" v-else-if="btnStatus == 1">
                                    <!-- <v-btn icon :color="btnStatus ? 'error' : 'success'" @click="validateUser(user)"><v-icon v-text="btnStatus ? 'mdi-cancel' : 'mdi-check'" /></v-btn> -->
                                    <v-btn icon color="primary" @click="validateUser(user, 1)"><v-icon>mdi-check</v-icon></v-btn>
                                </div>
                                <div class="flex" v-else-if="btnStatus == 3">
                                    <!-- <v-btn icon :color="btnStatus ? 'error' : 'success'" @click="validateUser(user)"><v-icon v-text="btnStatus ? 'mdi-cancel' : 'mdi-check'" /></v-btn> -->
                                    <v-btn icon color="primary" @click="checkFile(user)"><v-icon>mdi-file-document-outline</v-icon></v-btn>
                                    <v-btn icon color="error" @click="validateUser(user, 0)"><v-icon>mdi-cancel</v-icon></v-btn>
                                </div>
                                <div class="flex" v-else>
                                    <v-btn icon color="primary" @click="checkFile(user)"><v-icon>mdi-file-document-outline</v-icon></v-btn>
                                    <v-btn icon color="success" @click="validateUser(user, 1)"><v-icon>mdi-check</v-icon></v-btn>
                                    <v-btn icon color="error" @click="validateUser(user, 0)"><v-icon>mdi-cancel</v-icon></v-btn>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div class="text-center mt-2 noresults" v-else><b>Nenhum resultado encontrado!</b></div>
            </div>
        </div>
    </ContainerBox>

</template>

<script>
    import ContainerBox from '../components/ContainerBox.vue';
    import baseURL from '@/services/baseUrl';
    export default {
        name: "validarInscricao",
        data: () => ({
            verified: false,
            verifyHash: false,
            showpw: false,
            verifyError: false,
            concurso: '',
            idConcurso: '',
            accessCode: "",
            url: baseURL,
            btnStatus: 2,
            searchFilter: "",
            codeToSearch: "",
            users: []
        }),
        components: {
            ContainerBox,
        },
        methods: {
            required: (v) => !!v || "O campo é obrigatório",

            async searchCode() {
                this.$api.verifyInscriptionCode(this.codeToSearch).then(response => {
                    if (response.status === 200)
                        if (response.data) this.$swal(`Essa inscrição é válida.`, "", "success");
                        else this.$swal("Inscrição Inválida!", "", "error");
                }).catch(() => {
                    this.$swal("Inscrição Inválida!", "", "error");
                });
            },
            async loadContest(){
                this.idConcurso = this.$route.params.code
                var response = await this.$api.getContest(this.idConcurso)
                if(response.status != 200){
                    this.$router.push({
                    name: "NotFound"
                })
                }
                else{
                    this.concurso = response.data
                }
            },
            async loadUsers() {
                var response = await this.$api.GetInscriptionsByStatus(this.idConcurso, this.btnStatus).then((response) => { return response }).catch(console.error);
                this.users = response.data;
            },
            checkFile: function(user){
                window.open(user.paymentProofPath.replace('wwwroot', this.url))
            },
            validateUser: function (user, type) {
                this.$swal({ //type = 0 - Revogar ; type = 1 - Confirmar
                    title: type == 0  ? "Revogar Inscrição" : "Confirmar Inscrição",
                    html: `<b>${user.name}<br />${user.cpf}</b>`,
                    icon: "warning",
                    showConfirmButton: true,
                    showCancelButton: true,
                    confirmButtonText: type == 0 ? "Revogar" : "Confirmar",
                    cancelButtonText: "Cancelar",
                })
                .then(async (response) => {
                    if (response.isConfirmed) {
                        if (type == 0) {
                            await this.$api.revokeInscription(user.cpf, this.idConcurso).then(response => {
                                if (response.status === 204) {
                                    this.$swal({ title: "Revogado", html: `A confirmação da inscrição de <b>${user.name}</b> foi revogada.`, icon: "error" });
                                }
                            }).catch(console.error);
                        }else {
                            await this.$api.confirmInscription(user.cpf, this.idConcurso).then(response => {
                                if (response.status === 204) {
                                    this.$swal({ title: "Sucesso", html: `A inscrição de <b>${user.name}</b> foi confirmada.`, icon: "success" });
                                }
                            }).catch(console.error);
                        }
                        this.loadUsers();
                        // let aux = this.users;
                        // this.users = [];
                        // this.users = aux;
                    }
                });
            },
            async sendCode() {
                this.$api.verifyCode(this.accessCode).then(response => {
                    if (response.status === 200 && response.data) this.verified = true;
                    else this.verifyError = true;
                }).catch(console.error);
            }
        },
        computed: {
            getStatus() {
                switch (this.btnStatus) {
                    
                    case 4:
                        return 'Canceladas'
                    case 1:
                        return 'Pendentes'
                    case 2:
                        return 'Em Análise'
                    case 3: 
                        return 'Confirmadas'
                    default:
                        return 'Recusadas'
                }
            },
            filteredUsers() {
                if(this.searchFilter != undefined || this.searchFilter != '' || this.searchFilter != null){
                    return this.users.filter(user => {
                        return user.cpf.match(this.searchFilter)
                    })
                }
                else{
                    return this.users
                }
            },
            notverified() {
                return !this.verified;
            },
    },
        mounted() {
            this.loadUsers();
        },
        async beforeMount(){
        this.isAuth = this.$store.getters.isLoggedIn
        await this.loadContest();
    },
    }
</script>

<style>
    @import '@/Styles/validar.css';

    .col-1 {
        flex: 0 0 8.3333333333%;
        max-width: 8.3333333333%;
    }

    .col-5 {
        flex: 0 0 41.6666666667%;
        max-width: 41.6666666667%;
    }

    .col-4 {
        flex: 0 0 33.3333333333%;
        max-width: 33.3333333333%;
    }

    .col-2 {
        flex: 0 0 16.6666666667%;
        max-width: 16.6666666667%;
    }

    .col-1, .col-2, .col-4, .col-5 {
        width: 100%;
    }

    .square {
        border-radius: 2px !important;
    }

    .plnone {
        padding-left: 0px !important;
    }

    .noresults {
        font-size: 1.5rem;
        font-weight: 500;
        margin-top: 2rem;
        padding-bottom: 2rem;
    }

    .libtn {
        cursor: pointer;
        border-radius: 4px 4px 4px 4px;
    }

    .text-dark {
        color: black;
    }

    .text-primary {
        color: #0263b0;
    }
</style>