import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: "mdiSvg",
  },
  theme: {
    themes: {
      options: { customProperties: true },
      light: {
        primary: "#1570B2",
        neutral: "#f5f2f2",
        positive: "#17A536",
        negative: "#B11212"
      }
    }
  }
});
