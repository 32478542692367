<template>
    <footer class="ftr">
        <div class="d-flex flex-wrap justify-content-center flex-grow flex-column mg-auto">
            <hr />
            <img class="d-flex flex-wrap imgfooter" src="@/assets/footer.png">
        </div>
    </footer>
</template>

<script>
    export default {
        name: "FooterScreen",
    };
</script>

<style>
    .ftr {
        margin-top: 2rem;
        width: 100%;
        flex-grow: 1;
        display: flex;
    }
    .mg-auto {
        margin-top: auto
    }
    .imgfooter {
        max-width: 500px;
        max-width: 100%;
        height: auto;
        align-self: center;
    }
</style>