<template>
  <div class="container-box" style="padding-bottom: 5px !important">
    <slot></slot>
    <Footer />
  </div>
</template>

<script>
import Footer from './Footer.vue';
export default {
  name: "ContainerBox",
  components: {
    Footer
  }
};
</script>

<style scoped>
.container-box {
  flex-grow: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 3rem;
  border-color: var(--color-primary);
  border-width: 10px 0.5px 0 0.5px;
  border-style: solid;
  border-radius: 25px 25px 0 0;
}
@media (max-width: 599px) {
  .container-box {
    padding: 1rem;
    padding-top: 2rem;
    margin-left: 0px;
    margin-right: 0px;
  }
}
</style>
