<template>
  <v-btn :disabled="false" :color="color" @click="action"> 
    <v-icon color="white">{{ icon }}</v-icon>
    <span class="pl-2 text-white text-justify">{{ texto }}</span>
  </v-btn>
</template>

<script>
 export default{
    props: ['icon', 'texto', 'color','isdisabled', 'action']
 };
</script>