<template>
    <div class="container d-flex h-100">
        <div class="my-auto w-100 flex flex-col align-center">
            <v-card class="flex flex-col align-center py-5 px-10 bgr min" elevation="4" >
                <div>
                    <img src="../assets/icone-5.png" />
                    
                </div>
                <v-card class="w-100 mt-4" elevation="2" min-height="290px" min-width="250px">
                    <v-card-text class="flex flex-col align-center">
                        <span>Insira seu email</span>
                        <v-text-field
                            v-model="email"
                            :rules="[rules.required]"
                            label="Email"
                            color="#0263b0"
                            @keyup.enter="enviarEmail()"
                            class="w-full">
                        </v-text-field>
                        <span style="color: red">{{ errormsg }}</span>
                        <v-btn color="#0263b0" class="white--text w-full mt-2" @click="enviarEmail()">Enviar Email</v-btn>
                    </v-card-text>
                </v-card>
            </v-card>
        </div>
    </div>
</template>

<script>
export default {
  data () {
    return {
      loading: false,
      email: '',
      rules: {
        required: value => value !== '' ? true : 'Campo obrigatório.',
      },
      errormsg: ''
    }
  },
  computed: {
    loggedIn () {
      return this.$store.state.isAuth
    }
  },
  created () {
    if (this.loggedIn) {
      this.$router.push('/')
    }
  },
  methods: {
    enviarEmail () {
      if (!this.email) {
        this.$swal('', 'Campos obrigatórios não preenchidos.', 'warning')
        this.errormsg = 'Campos obrigatórios não preenchidos.'
      } else {
        this.loading = true
        this.$api.recuperarSenha(this.email).then((response) => {
          if(response.status == 200){
            this.loading = false
            this.$swal('Em alguns minutos você irá receber um email com um link para redifinição da sua senha', "", 'success').then(
                () => {
                    this.$router.push('/')
                    window.location.reload()
                }
            )
          }
          else{
            this.loading = false
            this.$swal('Erro', "Email incorreto", 'error')
          }
        }).catch(err => {
          this.loading = false
          // this.$swal('Erro', (err.response && err.response.data && err.response.data.message) || err.message || err.toString(), 'error')
          this.errormsg = (err.response && err.response.data && (err.response.data.details || (err.response.data.errors && Object.values(err.response.data.errors).toString()))) || err.message || err.toString()
        })
      }
    }
  }
}
</script>