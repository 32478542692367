<template>
    <div class="container d-flex h-100">
        <div class="my-auto w-100 flex flex-col align-center">
            <v-card class="flex flex-col align-center py-5 px-10 bgr min" elevation="4" >
                <div>
                    <img src="../assets/icone-5.png" />
                    
                </div>
                <v-card class="w-100 mt-4" elevation="2" min-height="290px" min-width="250px">
                    <v-card-text class="flex flex-col align-center">
                        <span>Redefina sua senha</span>
                        <v-text-field
                            v-model="user.senha"
                            :type="showpwd ? 'text': 'password'"
                            :append-icon="showpwd ? 'mdi-eye-off' : 'mdi-eye'"
                            :rules="[rules.required, rules.min]"
                            hint="Mínimo de 8 caracteres"
                            label="Nova Senha"
                            color="#0263b0"
                            @click:append="showpwd = !showpwd"
                            @keyup.enter="trocarSenha()"
                            class="w-full">
                        </v-text-field>
                        <v-text-field
                            v-model="user.repetirSenha"
                            :type="showpwd2 ? 'text': 'password'"
                            :append-icon="showpwd2 ? 'mdi-eye-off' : 'mdi-eye'"
                            :rules="[rules.required, rules.min, rules.equal]"
                            label="Repetir Senha"
                            color="#0263b0"
                            class="w-full mt-4"
                            hint="Mínimo de 8 caracteres"
                            @click:append="showpwd2 = !showpwd2"
                            @keyup.enter="trocarSenha()">
                        </v-text-field>
                        <span style="color: red">{{ errormsg }}</span>
                        <v-btn color="#0263b0" class="white--text w-full mt-2" @click="trocarSenha()">Alterar Senha</v-btn>
                    </v-card-text>
                </v-card>
            </v-card>
        </div>
    </div>
</template>

<script>

export default {
  data () {
    return {
      loading: false,
      user: {
        email: '',
        code: '',
        senha: '',
        repetirSenha: ''
      },
      showpwd: false,
      showpwd2: false,
      rules: {
        required: value => value !== '' ? true : 'Campo obrigatório.',
        min: v => v.length >= 8 || 'Mínimo de 8 caracteres',
        equal: v => v == this.user.senha || 'As senhas devem ser iguais'
      },
      errormsg: ''
    }
  },
  computed: {
    loggedIn () {
      return this.$store.state.isAuth
    }
  },
  async mounted () {
    var codigo = this.$route.query
    var verificaCodigo = await this.$api.VerifyCode(codigo.token)
    if(verificaCodigo.status != 200){
      this.$router.push('/')
    }
    this.user.code = codigo.token
    this.user.email = verificaCodigo.data.email
    if (this.loggedIn) {
      this.$router.push('/')
      
    }
  },
  methods: {
    trocarSenha () {
      if (!this.user.senha || !this.user.repetirSenha) {
        this.$swal('', 'Campos obrigatórios não preenchidos.', 'warning')
        this.errormsg = 'Campos obrigatórios não preenchidos.'
      } else {
        this.loading = true
        var usuario = {
            email: this.user.email,
            code: this.user.code,
            newPassword: this.user.senha
        }
        this.$api.AlterPassword(usuario).then((response) => {
          if(response.status == 200){
            this.loading = false
            this.$swal('Sucesso ao alterar a senha', "", 'success').then(
                () => {
                    this.$router.push('/login')
                    window.location.reload()
                }
            )
          }
          else{
            this.loading = false
            this.$swal('Erro', "Credenciais Incorretas", 'error')
          }
        }).catch(err => {
          this.loading = false
          // this.$swal('Erro', (err.response && err.response.data && err.response.data.message) || err.message || err.toString(), 'error')
          this.errormsg = (err.response && err.response.data && (err.response.data.details || (err.response.data.errors && Object.values(err.response.data.errors).toString()))) || err.message || err.toString()
        })
      }
    }
  }
}
</script>