<!-- eslint-disable vue/multi-word-component-names -->
<template>
    <ContainerBox>
        <div class="mx-10 flex-col flex gap-4">
            <h1 class=" text-4xl font-bold">
                {{ concurso.name }}
            </h1>
            <h2 class="mt-8">
                {{ concurso.descricao }}
            </h2>
            <div class="flex h-2/4 md:flex-col gap-2">
                <ContestContentCard icon="mdi-account-group" text="Vagas Remanescentes" :valueCard="concurso.contestVacancies > 0 ? concurso.contestVacancies : '0'"/>
                <div class="my-auto h-2/3 border-l-2 border-black"></div>
                <ContestContentCard icon="mdi-cash" text="Taxa de Inscrição" :valueCard="(concurso.inscriptionValue).toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})"/>
                <div class="my-auto h-2/3 border-l-2 border-black"></div>
                <ContestContentCard icon="mdi-calendar" text="Inscrições" :valueCard="'De ' + concurso.contestStartDate + ' a ' + concurso.inscriptionsFinalDate"/>
            </div>
            <div class="mt-8">
                <span class="text-xl font-bold">Documentos</span>
                <v-btn-toggle 
                :color="'#0263B0'"
                v-model="selectionButton" 
                class="flex flex-wrap group gap-4 justify-center pt-8">
                    <v-btn class="sm:w-full" value="Edital">Editais</v-btn>
                    <v-btn class="sm:w-full" value="Gabarito">Gabaritos</v-btn>
                    <v-btn class="sm:w-full" value="Caderno_De_Prova">Caderno de provas</v-btn>
                    <v-btn class="sm:w-full" value="Sala_De_Prova">Salas de prova</v-btn>
                </v-btn-toggle>
                <div class="mt-8">
                    <DocumentList v-if="selectionButton == 'Edital'" :list="documentos.filter(x => x.documentType == 'Edital')" />
                    <DocumentList v-if="selectionButton == 'Gabarito'" :list="documentos.filter(x => x.documentType == 'Gabarito')" />
                    <DocumentList v-if="selectionButton == 'Caderno_De_Prova'" :list="documentos.filter(x => x.documentType == 'Caderno_De_Prova')"  />
                    <DocumentList v-if="selectionButton == 'Sala_De_Prova'" :list="documentos.filter(x => x.documentType == 'Sala_De_Prova')"/>
                </div>
            </div>
            <hr class="border-1 border-black">
            <div class="mt-8">
                <span class="text-xl font-bold">Local de prova</span>
                <iframe class="m-auto mb-8" :src="concurso.googleAdressLink != null ? concurso.googleAdressLink : addressMocado " width="80%" height="450" style="border:0;" allowfullscreen loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
        </div>
        <div class="w-full" @click="dialog= true">
              <EnrollModal @desenroll="desenrollContest(concurso.id)" @enroll="enrollContest(concurso.id)"
                @sendedPaymentProof="status = 'Pagamento_Em_Analise'"
                @disableDialogRegistration="dialogRegistration = false"
                @disableDialog="dialog = false"
                :inscriptionId="inscriptionNumber"
                :inscriptionInitialDate="concurso.contestStartDate"
                :inscriptionFinalDate="concurso.inscriptionsFinalDate"
                :dialogRegistration="dialogRegistration"
                :isAuth="isAuth"
                :status="status"
                :dialog="dialog"
                :isEnroll="isEnroll"
                :isAdmin="isAdmin"/>
            </div>
            <div @click="$router.push({path: `/restrictedArea/${concurso.id}`});" v-if="isAdmin">
              <CustomButton class="w-full" color="#17A536" icon="mdi-cog" texto="Gerenciar" />
            </div>
    </ContainerBox>
</template>

<script>
import ContainerBox from '../components/ContainerBox.vue';
import ContestContentCard from '@/components/ContestContentCard.vue'
import CustomButton from '@/components/CustomButton.vue';
import DocumentList from '@/components/DocumentList.vue';
import EnrollModal from '@/components/EnrollModal.vue';
export default {
    components: {
    ContainerBox,
    ContestContentCard,
    CustomButton,
    DocumentList,
    EnrollModal
},
    async beforeMount(){
        this.idUser = this.$store.getters.getId
        this.isAuth = this.$store.getters.isLoggedIn
        this.isAdmin = this.$store.getters.role
        await this.loadContest();
        if(this.isAuth){
            this.loadStatusInscription();  
        }
    },
    async mounted(){
        
    },
    data(){
        return{
            // concurso: {
            //     titulo: "Processo Seletivo de Estágio em Direito 2023",
            //     descricao: "PROCESSO SELETIVO PÚBLICO PARA FORMAÇÃO DO QUADRO DE RESERVA DE ESTAGIÁRIOS DE NÍVEL SUPERIOR, DA ÁREA DE DIREITO, DA PROCURADORIA GERAL DO ESTADO.",
            //     vagas: "50",
            //     taxa: 25.50,
            //     dataInic: "13/08/2023",
            //     dataFim: "20/08/2023",
            //     localdeProva: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7835.1957710087345!2d-37.07139824609376!3d-10.918133699999991!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x71ab3c8aba69e6b%3A0x8aec6eae67413004!2sPROCURADORIA%20GERAL%20DO%20ESTADO%20DE%20SERGIPE!5e0!3m2!1spt-BR!2sbr!4v1690987282959!5m2!1spt-BR!2sbr"
            // },
            dialog: false,
            isEnroll: false,
            isAuth: false,
            isAdmin: false,
            dialogRegistration: false,
            inscriptionNumber: '',
            idUser: '',
            status: '',
            concurso: [],
            addressMocado: 'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15670.391542017467!2d-37.0631585!3d-10.9181337!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x71ab3c8aba69e6b%3A0x8aec6eae67413004!2sPROCURADORIA%20GERAL%20DO%20ESTADO%20DE%20SERGIPE!5e0!3m2!1spt-BR!2sbr!4v1691423362916!5m2!1spt-BR!2sbr',
            documentos: [],
            selectionButton: ''
        }
    },
    methods:{
        async loadStatusInscription(){
            var response = await this.$api.VerifyInscription(this.concurso.id, this.idUser).catch(console.error)
            if(response.status == 200){
                this.isEnroll = response.data
                
          }
          else{
            this.isEnroll = false
          }
          if(this.isAuth){
            var responseStatus = await this.$api.VerifyInscriptionByCandidate(this.concurso.id, this.idUser).catch(console.error)
            if(response.status == 200){
                this.status = responseStatus.data
            }
            var responseNumber = await this.$api.GetInscriptionStatus(this.concurso.id, this.idUser)
            if(responseNumber.status == 200){
                sessionStorage.setItem('idInscription', responseNumber.data.inscriptionNumber)
            }
          }
          
        },
        async enrollContest(contest){
        var response = await this.$api.InscriptionTo(contest, this.idUser).catch(console.error)
        if(response.status == 200 || response.status == 201){
          this.dialogRegistration = true
          this.isEnroll = true
          var responseNumber = await this.$api.GetInscriptionStatus(this.concurso.id, this.idUser)
            if(responseNumber.status == 200){
                sessionStorage.setItem('idInscription', responseNumber.data.inscriptionNumber)
            }
        }
        else{
          this.$swal('Erro', 'Ocorreu um erro durante a inscrição no concurso', 'error')
        }
        
        this.dialog = false
      },
      async desenrollContest(contest){
          await this.$api.CancelInscription(contest, this.idUser).then( (response) => {
              if(response.status == 204){
                  this.$swal('Sucesso', `Inscrição foi cancelada`,'success')
                  this.isEnroll = false,
                  this.dialog = false
              }
              else{
                  this.$swal('Erro', `Ocorreu um erro ao cancelar a inscrição`,'error')
                  this.dialog = false
              }
          })
      },
        async loadContest(){
            var response = await this.$api.getContest(this.$route.params.code)
            if(response.status != 200){
                    this.$router.push({
                    name: "NotFound"
                })
            }
            else{
                this.concurso = response.data
                this.documentos = this.concurso.listDocument
            }
        }
    }
}
</script>

<style>

.buttonSelection:focus{
    color: red;
}
</style>