<template>
    <div class="container d-flex h-100">
        <div class="my-auto w-100 flex flex-col align-center">
            <v-card class="flex flex-col align-center py-5 px-10 bgr min" elevation="4" >
                <div>
                    <img src="../assets/icone-5.png" />
                    
                </div>
                <v-card class="w-100 mt-4" elevation="2" min-height="290px" min-width="250px">
                    <v-card-text class="flex flex-col align-center">
                        <span>Entre com seu Usuário ou Email cadastrado</span>
                        <v-text-field
                            v-model="user.login"
                            :rules="[rules.required]"
                            label="Usuário"
                            color="#0263b0"
                            @keyup.enter="login()"
                            class="w-full">
                        </v-text-field>
                        <v-text-field
                            v-model="user.password"
                            :type="showpw ? 'text' : 'password'"
                            :append-icon="showpw ? 'mdi-eye-off' : 'mdi-eye'"
                            :rules="[rules.required, rules.min]"
                            label="Senha"
                            color="#0263b0"
                            counter
                            class="w-full mt-4"
                            hint="Mínimo de 8 caracteres"
                            @keyup.enter="login()"
                            @click:append="showpw = !showpw">
                        </v-text-field>
                        <span style="color: red">{{ errormsg }}</span>
                        <v-btn color="#0263b0" class="white--text w-full mt-2" @click="login()">Entrar</v-btn>
                    </v-card-text>
                </v-card>
                <span class="mt-2"><router-link to="/recuperarSenha">Esqueci minha senha</router-link></span>
                <span class="mt-2">Não possui uma conta? <router-link to="/cadastro">Registre-se</router-link></span>
                <b v-if="loading" class="text-center text-white">Carregando...</b>
            </v-card>
        </div>
    </div>
</template>

<script>

export default {
  name: 'LoadingImg',
  props: {
    show: Boolean
  },
  data () {
    return {
      loading: false,
      showpw: false,
      user: {
        login: '',
        password: ''
      },
      rules: {
        required: value => value !== '' ? true : 'Campo obrigatório.',
        min: v => v.length >= 8 || 'Mínimo de 8 caracteres'
      },
      errormsg: ''
    }
  },
  computed: {
    loggedIn () {
      return this.$store.state.isAuth
    }
  },
  created () {
    if (this.loggedIn) {
      this.$router.push('/')
    }
  },
  methods: {
    login () {
      if (!this.user.login || !this.user.password) {
        // this.$swal('', 'Campos obrigatórios não preenchidos.', 'warning')
        this.errormsg = 'Campos obrigatórios não preenchidos.'
      } else {
        this.loading = true
        this.$api.login(this.user).then((response) => {
          if(response.status == 200){
            this.loading = false
            sessionStorage.setItem('token', response.data.token)
            sessionStorage.setItem('role', response.data.role)
            sessionStorage.setItem('id', response.data.id)
            sessionStorage.setItem('etnia', response.data.etnia)
            this.$router.push('/')
            window.location.reload()
          }
          else{
            this.loading = false
            this.$swal('Erro', "Credenciais Incorretas", 'error')
          }
        }).catch(err => {
          this.loading = false
          // this.$swal('Erro', (err.response && err.response.data && err.response.data.message) || err.message || err.toString(), 'error')
          this.errormsg = (err.response && err.response.data && (err.response.data.details || (err.response.data.errors && Object.values(err.response.data.errors).toString()))) || err.message || err.toString()
        })
      }
    }
  }
}
</script>

<style>
.min {
    height: 563px !important;
    min-width: 384px !important;
}

.e-title {
  font-size: 2rem;
  color: white;
  font-weight: 700;
}

.bgr {
    background-color: rgba(100, 100, 100, .55);
}

.title {
    font-size: 1.2rem;
    padding-bottom: 2%;
}

.text-white {
    color: white;
}

.v-application {
    background-color: #efefef !important;
}

.justify-center {
    justify-content: center;
}

.align-center {
    position: relative;
    align-items: center;
}

.lg-spinner {
  display: flex;
  justify-content: center;
}

.lg-spinner::after {
  content: "";
  z-index: 999;
  min-width: 155px;
  min-height: 155px;
  border-radius: 50%;
  border: .50rem solid #ffffff;
  border-right-color: transparent;
  animation: circleAnimation .8s linear infinite;
}

#loginSpinner {
  position: absolute;
  right: 28.7%;
  top: 5.6%;
}

@keyframes circleAnimation {
  to {
    transform: rotate(1turn);
  }
}
</style>