<template>
  <main>
    <container-box>
      <div class="container-content" overflow:hidden>
        <div class="container-box-title">Cadastro</div>
        <div>
    <v-stepper v-model="stepperControl">
      <v-stepper-header>
        <v-stepper-step step="1">
          Informações Gerais
        </v-stepper-step>
        <v-divider></v-divider>

        <v-stepper-step step="2">
          Informações de Login
        </v-stepper-step>
      </v-stepper-header>
    </v-stepper>
  </div>
        <div style="padding: 20px"></div>
        <v-form ref="form" v-model="form1" data-form-id="form1" v-if="stepperControl == 1">
          <div class="col-md-12">
            <label> <b>Nome Completo*</b> </label>
            <v-text-field
              placeholder="Nome"
              v-model="nome"
              outlined
              :rules="[required, maxLength(nome, 150)]"
              type="text"
              append-icon="mdi-account-box-outline"
            ></v-text-field>
          </div>
          <div class="d-flex flex-row flex-wrap">
            <div class="col-md-6">
              <label> <b>CPF*</b> </label>
              <v-text-field
                placeholder="XXX.XXX.XXX-XX"
                @blur="verifyCPFExist(cpf)"
                v-mask="'###.###.###-##'"
                v-model="cpf"
                outlined
                :rules="[required, minLength(cpf, 14), verifyCPFExistRule(cpfExist)]"
                type="text"
                append-icon="mdi-card-account-details-outline"
                maxlength="14"
              ></v-text-field>
            </div>
            <div class="col-md-6">
              <label> <b>Naturalidade:*</b> </label>
              <v-text-field
                placeholder="Aracaju-SE"
                v-model="naturalidade"
                outlined
                :rules="[required, maxLength(naturalidade, 100)]"
                type="text"
                append-icon="mdi-home-map-marker"
              ></v-text-field>
            </div>
          </div>
          <div class="d-flex flex-row flex-wrap">
            <div class="col-md-6">
              <label> <b>RG</b> </label>
              <v-text-field
                placeholder="XXXXXXXX"
                v-model="rg"
                outlined
                type="text"
                v-mask="'########'"
                append-icon="mdi-account-outline"
              ></v-text-field>
            </div>
            <div class="col-md-6">
              <label> <b>Órgão Expedidor:</b> </label>
              <v-text-field
                placeholder="SSP"
                v-model="orgao_exp"
                outlined
                :rules="[maxLength(orgao_exp, 150)]"
                type="text"
                append-icon="mdi-domain"
              ></v-text-field>
            </div>
          </div>
          <div class="col-md-12">
            <label> <b>Nome do Pai</b> </label>
            <v-text-field
              placeholder="Nome do pai"
              v-model="pai"
              outlined
              :rules="[maxLength(pai, 150)]"
              type="text"
              append-icon="mdi-human-male-boy"
            ></v-text-field>
          </div>
          <div class="col-md-12">
            <label> <b>Nome da Mãe*</b> </label>
            <v-text-field
              placeholder="Nome da mãe"
              v-model="mae"
              outlined
              :rules="[required, maxLength(mae, 150)]"
              type="text"
              append-icon="mdi-mother-heart"
            ></v-text-field>
          </div>
          <div class="d-flex flex-row flex-wrap">
            <div class="col-md-6">
              <label> <b>Estado Cívil:*</b> </label>
              <v-select
                v-model="e_civil"
                outlined
                :rules="[required]"
                :items="lista_e_civil"
                item-text="name"
                item-value="value"
                append-icon="mdi-ring"
              ></v-select>
            </div>
            <div class="col-md-6">
              <label> <b>Gênero:*</b> </label>
              <v-select
                v-model="genero"
                outlined
                :rules="[required]"
                :items="lista_genero"
                item-text="name"
                item-value="value"
                append-icon="mdi-account-box-outline"
              ></v-select>
            </div>
          </div>
          <div class="d-flex flex-wrap">
            <div class="col-md-6">
              <label> <b>Etnia:*</b> </label>
              <v-select
                v-model="etnia"
                outlined
                item-text="name"
                item-value="value"
                :items="lista_etnia"
                append-icon="mdi-account"
                :rules="[required]"
              ></v-select>
            </div>
            <div class="col-md-6">
              <label> <b>Data de Nascimento:*</b> </label>
              <v-text-field
                v-model="dataNasc"
                outlined
                min="1920-01-01"
                max="2023-07-28"
                :rules="[required]"
                type="date"
              ></v-text-field>
            </div>
          </div>
          <div class="col-md-12">
            <label> <b>Endereço Residencial:*</b> </label>
            <v-text-field
              placeholder="Rua XX Nº XXX"
              v-model="endereco"
              outlined
              :rules="[required, maxLength(endereco, 500)]"
              type="text"
              append-icon="mdi-map-marker-outline"
            ></v-text-field>
          </div>

          <div class="d-flex flex-wrap">
            <div class="col-md-6">
              <label> <b>CEP:*</b> </label>
              <v-text-field
                placeholder="XXXXX-XXX"
                v-model="cep"
                outlined
                v-mask="'#####-###'"
                :rules="[required, minLength(cep,9)]"
                type="text"
                append-icon="mdi-map-marker-radius-outline"
              ></v-text-field>
            </div>
            <div class="col-md-6">
              <label> <b>Bairro:*</b> </label>
              <v-text-field
                placeholder="Bairro"
                v-model="bairro"
                outlined
                :rules="[required, maxLength(bairro, 250)]"
                type="text"
                append-icon="mdi-home-city"
              ></v-text-field>
            </div>
          </div>
          <div class="d-flex flex-row flex-wrap">
            <div class="col-md-4">
              <label> <b>Cidade:*</b> </label>
              <v-text-field
                placeholder="Cidade"
                v-model="cidade"
                outlined
                :rules="[required, maxLength(cidade, 250)]"
                type="text"
                append-icon="mdi-city-variant"
              ></v-text-field>
            </div>
            <div class="col-md-4">
              <label> <b>Estado:*</b> </label>
              <v-text-field
                placeholder="Estado"
                v-model="estado"
                outlined
                :rules="[required, maxLength(estado, 250)]"
                type="text"
                append-icon="mdi-domain"
              ></v-text-field>
            </div>
            <div class="col-md-4">
              <label> <b>País:*</b> </label>
              <v-text-field
                placeholder="País"
                v-model="pais"
                outlined
                :rules="[required, maxLength(pais, 250)]"
                type="text"
                append-icon="mdi-flag"
              ></v-text-field>
            </div>
          </div>
          <div class="d-flex flex-row flex-wrap">
            <div class="col-md-6">
              <label> <b>Telefone Primário (Celular):*</b> </label>
              <v-text-field
                placeholder="(XX)9XXXX-XXXX"
                v-model="primaryPhone"
                outlined
                v-mask="'(##)#####-####'"
                :rules="[required, minLength(primaryPhone,14), validatePhone(primaryPhone)]"
                type="text"
                append-icon="mdi-cellphone"
              ></v-text-field>
            </div>
            <div class="col-md-6">
              <label> <b>Telefone Secundário:</b> </label>
              <v-text-field
                placeholder="(XX)9XXXX-XXXX"
                v-model="secondaryPhone"
                outlined
                v-mask="'(##)#####-####'"
                type="text"
                append-icon="mdi-cellphone"
              ></v-text-field>
            </div>
          </div>
          <div class="d-flex flex-row flex-wrap">
            <div class="col-md-6">
              <label> <b>Email:*</b> </label>
              <v-text-field
                @blur="verifyEmailExist(email)"
                placeholder="email@dominio.com"
                v-model="email"
                outlined
                :rules="[required, maxLength(email, 300), verifyEmail(email), verifyEmailExistRule(emailExist)]"
                type="text"
                append-icon="mdi-at"
              ></v-text-field>
            </div>
            <div class="col-md-6">
              <label> <b>Universidade:*</b> </label>
              <v-select
                :items="lista_universidade"
                item-text="name"
                item-value="value"
                v-model="universidade"
                outlined
                v-on:change="verifyUniv"
                :rules="[required]"
                append-icon="mdi-google-classroom"
              ></v-select>
            </div>
          </div>
          <div v-if="checkUniv" class="col-md-12">
            <label> <b>Digite o nome da Universidade*</b> </label>
            <v-text-field
              placeholder="Universidade"
              v-model="name_univ"
              outlined
              :rules="[required, maxLength(name_univ, 250)]"
              type="text"
              append-icon="mdi-book"
            ></v-text-field>
          </div>
          <div class="mb-4 px-3">
            <input
              type="checkbox"
              class="pa-0"
              id="checkbox"
              v-model="tem_defic"
            />
            <label class="pa-1 mt-n1" for="checkbox">
              <b>Possui alguma deficiência? </b>
            </label>
          </div>
          <div v-if="checkDefic" class="col-md-12">
            <label> <b>Descreva a sua deficiência*</b> </label>
            <v-text-field
              placeholder="Descrição"
              v-model="name_defic"
              outlined
              :rules="[required, maxLength(name_defic, 250)]"
              type="text"
              append-icon="mdi-human-wheelchair"
            ></v-text-field>
          </div>
          <div class="px-3 mt-4 pt-4">
            <v-btn class="app-button" :disabled="!form1" color="primary" @click="cadastroEtapa1"><slot>Prosseguir</slot></v-btn>
          </div>
        </v-form>
        <v-form ref="form2" v-model="form2" @submit.prevent="cadastrar" v-if="stepperControl == 2">
          <div class="col-md-12">
            <label> <b>Usuário*</b> </label>
            <v-text-field
              placeholder="Usuario"
              v-model="login"
              outlined
              :rules="[required, maxLength(login, 150)]"
              type="text"
              append-icon="mdi-account-box-outline"
            ></v-text-field>
          </div>
          <div class="d-flex flex-row flex-wrap">
            <div class="col-md-6">
              <label> <b>Senha*</b> </label>
              <v-text-field
                v-model="password"
                outlined
                :rules="[required, minLength(password,8)]"
                item-text="password"
                :type="verSenha ? 'text': 'password'"
                :append-icon="verSenha ? 'mdi-eye-off': 'mdi-eye'"
                @click:append="verSenha = !verSenha"
              ></v-text-field>
              </div>
              <div class="col-md-6">
              <label> <b>Confirmar senha*</b> </label>
              <v-text-field
                v-model="confirmPassword"
                outlined
                :rules="[required, comparaSenha(password, confirmPassword), minLength(password,8)]"
                :type="verConfirmSenha ? 'text': 'password'"
                item-text="password"
                :append-icon="verConfirmSenha ? 'mdi-eye-off': 'mdi-eye'"
                @click:append="verConfirmSenha = !verConfirmSenha"
              ></v-text-field>
              </div>
            </div>
            <div class="px-3 mt-4 pt-4 flex"><div class="col-md-6">
              <v-btn class="app-button" :disabled="!form2" color="primary" type="submit"><slot>Cadastrar</slot></v-btn>
                </div>
              <div class="col-md-6">
                <v-btn class="app-button" color="error" @click="stepperControl = 1"><slot>Voltar</slot></v-btn>
              </div>
            </div>
        </v-form>
      </div>
    </container-box>
  </main>
</template>
  
<script>
  //import AppButton from "../components/AppButon.vue";
  import ContainerBox from "../components/ContainerBox.vue";
  //import * as candidateService from "@/services/candidateService.js";
  export default {
    name: "telaConcursos",
    components: {
      //AppButton,
      ContainerBox,
    },
    data() {
      return {
        form2: "2",
        form1: "1",
        nome: "",
        code: "",
        cpf: "",
        naturalidade: "",
        rg: "",
        orgao_exp: "",
        pai: "",
        mae: "",
        e_civil: 0,
        genero: 0,
        dataNasc: "",
        endereco: "",
        cep: "",
        bairro: "",
        cidade: "",
        estado: "",
        pais: "",
        password: "",
        confirmPassword: "",
        primaryPhone: "",
        secondaryPhone: "",
        email: "",
        universidade: 0,
        login: "",
        name_univ: "",
        name_defic: "",
        verSenha: false,
        verConfirmSenha: false,
        tem_defic: false,
        outra_univ: false,
        stepperControl: 1,
        cpfExist: false,
        emailExist: false,
        userExist: false,
        etnia: 0,
        // mensagem: "ESTA INSCRIÇÃO SOMENTE SERÁ VALIDADA MEDIANTE O COMPARECIMENTO DO CANDIDATO À SEDE DA PGE/SE, NOS DIAS 06 E 07 DE FEVEREIRO DE 2023, MUNIDO DE DUAS LATAS/PACOTES DE LEITE EM PÓ DE 400g CADA, QUE SERÃO DESTINADOS À DOAÇÃO, NOS TERMOS DO ITEM 1.4 DO EDITAL 01/2023",
        lista_genero: [{value: 0, name: "Selecione"}],
        lista_e_civil: [{value: 0, name: "Selecione"}],
        lista_universidade: [{value: 0, name: "Selecione"}],
        lista_etnia: [{value:0, name: 'Selecione'}]
      };
    },
    methods: {
      validatePhone: (v) => /^\(\d{2}\)[9]\d{4}-\d{4}$/.test(v) || "O formato é inválido",
      required: (v) => (!!v && v.toString().split(" ").join("").length != 0) || "O campo é obrigatório",
      maxLength: (v, lg) => v.length < lg || `O tamanho máximo é de ${lg} caracteres.`,
      verifyEmail: (v) => /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(v) || 'Email com formato inválido',
      verifyCPFExistRule: (v) => !v || 'CPF já cadastrado',
      verifyEmailExistRule: (v) => !v || 'Email já cadastrado',
      verifyUserExistRule: (v) => !v || 'Login já cadastrado',
      minLength: (v, lg) => v.length >= lg ||  `O tamanho mínimo é de ${lg} caracteres.`,
      comparaSenha: (v1, v2) => v1 === v2 || 'As senhas não conferem',
      requireCheck(v) {
        if (!v || v.toString().split(" ").join("").length == 0) return "awd5*1a2sd@84asd#18w5%_require_failed";
        return v;
      },
      async verifyCPFExist(cpf){
        await this.$api.VerifyCPFExist(cpf).then((response) => {
          if(response.status == 200){
            this.cpfExist = response.data
          }
        }).catch(console.error)
      },
      async verifyEmailExist(email){
         await this.$api.VerifyEmailExist(email).then((response) => {
          if(response.status == 200){
            this.emailExist = response.data
          }
         }).catch(console.error)
      },
      async verifyUserExist(user){
         await this.$api.VerifyUserExist(user).then((response) => {
          if(response.status == 200){
            this.userExist = response.data
          }
         }).catch(console.error)
      },
      verifyUniv() {
        if (this.lista_universidade.find(x => x.value === this.universidade)?.name == "Outro") this.outra_univ = true;
        else this.outra_univ = false;
      },

      async loadDropdowns() {
        await this.$api.getEnums().then(response => {
          console.log(response)
          this.lista_universidade = this.lista_universidade.concat(response.data.universisties);
          this.lista_genero = this.lista_genero.concat(response.data.genders);
          this.lista_e_civil = this.lista_e_civil.concat(response.data.civilStates);
          this.lista_etnia = this.lista_etnia.concat(response.data.ethnicity);
        }).catch(console.error);
      },
      async cadastroEtapa1() {
        // var content = this.getCandidateInfo;
        // if (Object.values(content).some(x => x === "awd5*1a2sd@84asd#18w5%_require_failed")) {
        //   let n = Object.values(content).filter(x => x === "awd5*1a2sd@84asd#18w5%_require_failed").length;
        //   this.$swal(n !== 1 ? "Campos obrigatórios não preenchidos." : "Campo obrigatório não preenchido", "", "error");
        // }
        // else {
        //   this.stepperControl = 2
        // }
        this.stepperControl = 2
      },
      async cadastrar() {
        await this.verifyUserExist(this.login)
        if(!this.userExist){
          var content = this.getCandidateInfo;
          if (Object.values(content).some(x => x === "awd5*1a2sd@84asd#18w5%_require_failed")) {
            let n = Object.values(content).filter(x => x === "awd5*1a2sd@84asd#18w5%_require_failed").length;
            this.$swal(n !== 1 ? "Campos obrigatórios não preenchidos." : "Campo obrigatório não preenchido", "", "error");
          }
          else {
            await this.$api.register(content).then((response) => {
              if (response.status === 201) {
                this.$swal('Sucesso',"Conta cadastrada com sucesso", 'success');
                this.$router.push("Login")
              }
              else{
                this.$swal('Erro',"Ocorreu um erro durante o cadastro", 'error');
              }
            }).catch(err => {
              this.$swal('Erro', err, 'error');
            });
          }
        }
        else{
          this.$swal('Erro',"Usuário já existente, insira um novo nome", 'error');
        }
      }
        
    },
    computed: {
      checkUniv: function () {
        return this.outra_univ;
      },
      checkDefic: function () {
        return this.tem_defic;
      },
      getCandidateInfo: function () {
        return {
          code: '',
          login: this.requireCheck(this.login),
          email: this.requireCheck(this.email),
          password: this.requireCheck(this.password),
          fullName: this.requireCheck(this.nome),
          birthDate: this.requireCheck(this.dataNasc),
          cpf: this.requireCheck(this.cpf),
          rg: this.rg,
          dispatcherEntity: this.orgao_exp,
          gender: this.requireCheck(this.genero),
          birthPlace: this.requireCheck(this.naturalidade),
          fatherName: this.pai,
          motherName: this.requireCheck(this.mae),
          civilState: this.requireCheck(this.e_civil),
          civilStateText: "string",
          university: this.requireCheck(this.universidade),
          universityText: this.outra_univ ? this.name_univ : "",
          primaryPhone: this.requireCheck(this.primaryPhone),
          secondaryPhone: this.secondaryPhone,
          isDeficient: this.tem_defic,
          deficientText: this.tem_defic ? this.name_defic : "",
          cep: this.requireCheck(this.cep),
          street: this.requireCheck(this.endereco),
          district: this.requireCheck(this.bairro),
          city: this.requireCheck(this.cidade),
          state: this.requireCheck(this.estado),
          country: this.requireCheck(this.pais),
          ethnicity: this.requireCheck(this.etnia)
        }
      }
    },
    mounted() {
      this.loadDropdowns();
    }
  };
</script>
  
<style scoped>
  main {
    display: flex;
    height: 100%;
    flex-direction: column;
  }

  .app-button {
    width: 100%;
  }
  .v-sheet.v-card {
    border-radius: 10px;
  }
  .v-toolbar__title {
    font-size: 28px;
    font-weight: 600;
  }
  .col-xl,
  .col-xl-auto,
  .col-xl-12,
  .col-xl-11,
  .col-xl-10,
  .col-xl-9,
  .col-xl-8,
  .col-xl-7,
  .col-xl-6,
  .col-xl-5,
  .col-xl-4,
  .col-xl-3,
  .col-xl-2,
  .col-xl-1,
  .col-lg,
  .col-lg-auto,
  .col-lg-12,
  .col-lg-11,
  .col-lg-10,
  .col-lg-9,
  .col-lg-8,
  .col-lg-7,
  .col-lg-6,
  .col-lg-5,
  .col-lg-4,
  .col-lg-3,
  .col-lg-2,
  .col-lg-1,
  .col-md,
  .col-md-auto,
  .col-md-12,
  .col-md-11,
  .col-md-10,
  .col-md-9,
  .col-md-8,
  .col-md-7,
  .col-md-6,
  .col-md-5,
  .col-md-4,
  .col-md-3,
  .col-md-2,
  .col-md-1,
  .col-sm,
  .col-sm-auto,
  .col-sm-12,
  .col-sm-11,
  .col-sm-10,
  .col-sm-9,
  .col-sm-8,
  .col-sm-7,
  .col-sm-6,
  .col-sm-5,
  .col-sm-4,
  .col-sm-3,
  .col-sm-2,
  .col-sm-1,
  .col,
  .col-auto,
  .col-12,
  .col-11,
  .col-10,
  .col-9,
  .col-8,
  .col-7,
  .col-6,
  .col-5,
  .col-4,
  .col-3,
  .col-2,
  .col-1 {
    width: 100%;
    padding: 12px;
  }

  @media (min-width: 960px) {
    .col-md-6 {
      flex: 0 0 50%;
      max-width: 50%;
    }
    .col-md-4 {
      flex: 0 0 33.3333333333%;
      max-width: 33.3333333333%;
    }
    .col-md-12 {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }

  @media (min-width: 600px) {
    .col-sm-12 {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
</style>