import axios from 'axios';
import baseURL from './baseUrl';
var token = sessionStorage.getItem('token')


const http = axios.create({
  baseURL: baseURL,
  headers: { 
		'Authorization': `Bearer ${token}` 
	}
});

export default http;
