<!-- eslint-disable vue/valid-v-for -->
<template>
  <ContainerBox>
    <v-dialog persistent v-model="cardUpdateEtnia" width="750">      
      <v-card :class="'p-2 ' + borderClassModal">
        <v-card-title class="d-flex align-center justify-center">
          <v-icon v-if="titleIcon" :icon="titleIcon" :color="detailsColor" class="mr-2" size="large" />
          <span class="text-4xl font-bold"> Atualização Cadastral </span>
        </v-card-title>
        <hr class="mt-2"/>
        <v-card-text>
          <h1 class="text-left text-xl text-black mt-2 ">
            Para dar continuidade ao uso do Concursos PGE/SE, é necessário preencher a autodeclaração étnico-racial, pois seus dados cadastrais não contêm essa informação.
          </h1>
          <div class="col-md-6 mt-4">
          <v-select
                v-model="etnia"
                outlined
                item-text="name"
                item-value="value"
                :items="lista_etnia"
                append-icon="mdi-account"
                :rules="[required]"
          ></v-select>
          </div>
          <div class="d-flex col-md-6 justify-content-center w-full">
            <v-btn block color="#17A536" @click="() => CadastrarEtnia()" :disabled="etnia == 0 || etnia == 'null'">
              <v-icon color="white">mdi-plus-box</v-icon>
              <span class="pl-2 text-white text-justify">Cadastrar</span>
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <div id="Panel" class="d-flex flex-column align-items-start flex-grow">
      <div class="container-box-title">Inscrições Abertas</div>
      <span class="font-bold text-2xl" v-if="concursos.length == 0">Não há nenhum concurso com inscrições abertas no momento </span>
      <div class="w-full h-[100vh] flex gap-3 items-start flex-wrap sm:justify-center" v-else>
        <v-card outlined class="contest-card lrounded rounded-lg mr-3 my-3" width="450" v-for="(card, index) in concursos">
          <v-card-title class="text-2xl font-semibold">{{card.name}}</v-card-title>
          <v-card-text class="text-lg black--text">Inscrições até {{ card.inscriptionsFinalDate }}</v-card-text>
          <v-card-actions class="ml-4 pr-4 pb-4 gap-2 m-auto sm:gap-1 sm:flex-col">
            <div @click="$router.push({path: `/concurso/${card.id}` }).catch(()=>{});">
              <CustomButton color="#1570B2" icon="mdi-plus-circle" texto="Mais informações"/>
            </div>
            <div @click="card.dialog = true, idContest = card.id, idIndex = index">
              <EnrollModal v-if="!isAdmin" @desenroll="desenrollContest(idIndex, card)" @enroll="enrollContest(idContest, idIndex, card)"
                @enrolled="card.status = 'Pagamento_Pendente'"
                @sendedPaymentProof="card.status = 'Pagamento_Em_Analise'"
                @disableDialog="card.dialog = false"
                @disableDialogRegistration="card.dialogRegistration = false"
                :inscriptionId="inscriptionId"
                :isAuth="isAuth"
                :status="card.status"
                :dialog="card.dialog"
                :dialogRegistration="card.dialogRegistration"
                :inscriptionInitialDate="card.contestStartDate"
                :inscriptionFinalDate="card.inscriptionsFinalDate"
                :isEnroll="card.isEnroll"
                :isAdmin="isAdmin"/>
            </div>
            <div @click="$router.push({path: `restrictedArea/${card.id}`}).catch(()=>{});" v-if="isAdmin">
              <CustomButton color="#17A536" icon="mdi-cog" texto="Gerenciar" />
            </div>
          </v-card-actions>
        </v-card>
      </div>
    </div>
 
  </ContainerBox>
</template>

<script>
  import ContainerBox from '../components/ContainerBox.vue';
  import CustomButton from '@/components/CustomButton.vue';
  import EnrollModal from '@/components/EnrollModal.vue';

  export default {
    name: 'HomePage',
    components: {
    ContainerBox,
    CustomButton,
    EnrollModal,
    },
    data: () => ({
        dialog: false,
        inscriptionId: '',
        qrcode: '00020126600014br.gov.bcb.pix0114348412580001320220CONCURSO ESTÁGIO PGE520400005303986540520.005802BR5924PROCURADORIA GERAL DO ES6007Aracaju62240520BES189c096c61028faa363047A46',
        isCopy: false,
        idContest: '',
        teste1: true,
        isEnroll: false,
        dialogRegistration: false,
        isAdmin: false,
        isAuth: false,
        idUser: '',
        idIndex: '',
        concursos: [],
        isEnrollValueAux: '',
        cardUpdateEtnia: false,
        statusAux: '',
        etnia: 0,
        lista_etnia: [{value:0, name: 'Selecione'}]
    }),
    methods: {
      async CadastrarEtnia(){
        var idCandidate = this.$store.getters.getId
        var response = await this.$api.inserirEtnia(idCandidate, this.etnia).catch(console.error)
        if(response.status == 204){
          this.$swal('Sucesso', `Etnia cadastrada`,'success')
          sessionStorage.setItem('etnia', this.etnia)
          this.cardUpdateEtnia = false
        }
        else{
          this.$swal('Erro', `Etnia não foi cadastrada, tente novamente em alguns minutos`,'error')
          this.cardUpdateEtnia = true
        }
      },
      async enrollContest(contest, index, card){
        card.dialog = false
        var idCandidate = this.$store.getters.getId
        var response = await this.$api.InscriptionTo(contest, idCandidate).catch(console.error)
        if(response.status == 200){
          sessionStorage.setItem('idInscription', response.data.inscription)
          card.dialogRegistration = true
          this.concursos[index].isEnroll = true
          card.isEnroll = true
        }
        else if(response.status == 201){
          sessionStorage.setItem('idInscription', response.data)
          card.dialogRegistration = true
          this.concursos[index].isEnroll = true
          card.isEnroll = true
        }
        else{
          this.$swal('Erro', 'Ocorreu um erro durante a inscrição no concurso', 'error')
        }
        
        this.dialog = false
      },
      expnd(concurso) {
        let index = this.expand.findIndex(x => x === concurso.id);
        if (index >= 0) this.expand.splice(index, 1);
        else this.expand.push(concurso.id);
      },
      async desenrollContest(index, card){
          await this.$api.CancelInscription(this.concursos[index].id, this.idUser).then( (response) => {
              if(response.status == 204){
                  this.$swal('Sucesso', `Inscrição foi cancelada`,'success')
                  this.concursos[index].isEnroll = false,
                  card.dialog = false
              }
              else{
                  this.$swal('Erro', `Ocorreu um erro ao cancelar a inscrição`,'error')
                  card.dialog = false
              }
          })
      },
      
      async loadContests() {
        var response = await this.$api.getContestsWithEnrollOpen().catch(console.error);
        var auxReponse = response.data
        if(!this.isAuth){
          for(var i = 0; i < auxReponse.length; i++){
            const aux = {
              id: auxReponse[i].id,
              inscriptionsFinalDate: auxReponse[i].inscriptionsFinalDate,
              contestStartDate: auxReponse[i].contestStartDate,
              name: auxReponse[i].name,
              isEnroll: false,
              dialog: false
            };
            this.concursos.push(aux)
          }
        }
        else{
          for(var j = 0; j < auxReponse.length; j++){
            var responseInscription = await this.$api.VerifyInscription(auxReponse[j].id, this.idUser).catch(console.error)
            if(responseInscription.status == 200){
              this.isEnrollValueAux = responseInscription.data
              var statusResponse = await this.$api.VerifyInscriptionByCandidate(auxReponse[j].id, this.idUser).catch(console.error)
              if(statusResponse.status == 200){

                this.statusAux = statusResponse.data
              }
            }
            else{
              this.isEnrollValueAux = false
            }
            const aux = {
              id: auxReponse[j].id,
              inscriptionsFinalDate: auxReponse[j].inscriptionsFinalDate,
              contestStartDate: auxReponse[j].contestStartDate,
              name: auxReponse[j].name,
              isEnroll: this.isEnrollValueAux,
              status: this.statusAux,
              dialog: false,
              dialogRegistration: false
            };
          this.concursos.push(aux)
          }
        }
      },
      checkDate(concurso) {
        var now = Date.now();
        return (now >= new Date(concurso.contestStartDate) && now <= new Date(concurso.contestFinalDate));
      },
    },
    computed: {
      updateEtnia: function (){
        console.log(this.isAuth && this.etnia)
        return (this.isAuth && this.etnia == null)
      },
      getConcursos: function () {
        return this.concursos;
      },
      checkExpand() {
        return this.expand;
      },
    },
    async mounted() {
      this.isAdmin = this.$store.getters.role
      this.isAuth = this.$store.getters.isLoggedIn
      this.idUser = this.$store.getters.getId
      this.etnia = this.$store.getters.getEtnia
      this.loadContests()
      if(this.isAuth && this.etnia == 'null'){
        var response = await this.$api.dropdownEtnia()
        console.log(response)
        if(response.status == 200){
          this.lista_etnia = this.lista_etnia.concat(response.data)
        }
        this.cardUpdateEtnia = true
      }
    },
    
}
</script>

<style>

  .text-black {
    color: black !important;
  }

  .square {
    border-radius: 2px !important;
  }

  .pointer {
    cursor: pointer;
  }

  .mh-350 {
    height: 320px;
    max-height: 320px;
  }

  .mh-370 {
    max-height: 370px;
  }

  .lrounded {
    filter: drop-shadow(0px 5px 4px #494949);
  }

  @media only screen and (max-width: 1000px) {
    .lrounded {
      border-radius: 1rem !important;
      border: 0.3rem solid #0263b0 !important;
      background-color: white !important;
      align-self: flex-start;
    }
  }
  .v-card.borderCard{
    border-left: 7px solid #0263b0 !important;
  }
</style>
