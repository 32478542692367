<template>
  <v-app :class="!disableHeader ? 'main-app' : ''">
    <Loading show=""></Loading>
    <NavBar v-if="!disableHeader"></NavBar>
    <v-main>
      <div :class="['app-main', disableHeader ? '' : 'container']">
        <router-view />
      </div>
    </v-main>
    <div class="absolute bottom-0 right-5">
      <v-icon :color="'#0263b0'" size="4rem" @click="ajuda = true">mdi-help-circle</v-icon>
    </div>
    <v-dialog max-width="500" v-model="ajuda">
      <v-card title="Dialog" class="flex justify-center align-center flex-column">
        <v-card-title class="flex justify-center">
          <v-icon :color="'#0263b0'" size="4rem">
            mdi-help-circle
          </v-icon>
          <h1>Ocorreu algum problema durante sua inscrição?</h1>
        </v-card-title>
        <div class="flex justify-center">
          <v-card-text class="flex justify-center flex-column">
            Entre em contato com a nossa equipe técnica via email:
            <br>
            <span class="text-center font-bold">tecnologia@pge.se.gov.br</span>
          </v-card-text>
        </div>
    </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import "./Styles/global.css";

import NavBar from "./components/NavBar.vue";

import Loading from "./components/Loading.vue";

export default {
  name: "App",
  data: () => ({
    routesWithNavbarDisabled: ["restrictedArea"],
    show: [],
    ajuda: false,
  }),
  components: {
    NavBar,
    Loading,
  },
  computed: {
    disableHeader: function () {
      return this.routesWithNavbarDisabled.includes(this.$route.name);
    },
  },
  methods: {
    loadIntercepts() {
      let axios = this.$api.getHttp();
      axios.interceptors.request.use(
        (config) => {
          this.show.push(true);
          document.getElementById("overlay").className = "overlay show";
          document.getElementById("spanner").className = "LoadingFullscreen spanner show";
          return config;
        },
        (error) => {
          document.getElementById("overlay").className = "overlay";
          document.getElementById("spanner").className = "LoadingFullscreen spanner";
          throw error;
        }
      );

      axios.interceptors.response.use(
        (response) => {
          this.show = this.show.slice(1);
          if (this.show?.length === 0) {
            document.getElementById("overlay").className = "overlay";
            document.getElementById("spanner").className = "LoadingFullscreen spanner";
          }
          return response;
        },
        (error) => {
          if(this.show.length > 0)
            this.show = this.show.slice(1);

          document.getElementById("overlay").className = "overlay";
          document.getElementById("spanner").className = "LoadingFullscreen spanner";
          throw error;
        }
      );
    }
  },
  mounted() {
    this.loadIntercepts();
  }
};
</script>

<style>
* {
  text-transform: none !important;
  font-family: "Roboto", sans-serif;
}

body {
  background-color: #efefef;
}

.main-app {
  background-color: #efefef !important;
  display: flex;
  flex-direction: column;
}

.app-main {
  padding: 0 !important;
  flex-grow: 1;
  height: 100%;
}

.v-input__slot {
  font-size: 14px;
}
.swal2-styled.swal2-confirm {
  background-color: var(--color-primary) !important;
  box-shadow: none !important;
}

.show {
  z-index: 999;
  visibility: visible;
}

.overlay {
  position: fixed;
  width: 100%;

  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  visibility: hidden;
}

.spanner,
.overlay {
  opacity: 0;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
}

.spanner.show,
.overlay.show {
  opacity: 1;
}
</style>

