<template>
    <div id="Loading">
        <div id="overlay" :class="'overlay ' + show" style="z-index: 999 !important;"></div>
        <div id="spanner" :class="'LoadingFullscreen spanner ' + show"> 
            <v-overlay opacity="0.7">
                <v-img :lazy-src="require(`../assets/icone-5.png`)"
                    :src="require(`../assets/icone-5.png`)"
                    contain
                    absolute
                    max-width="150px" />
                <b-spinner id="loadingSpinner"></b-spinner>
                <p class="mt-2" id="loadingText">Carregando...</p>
            </v-overlay>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'LoadingImg',
        props: ['show']
    }
</script>

<style>
    @import './Loading.css';
</style>
