<template>
  <ContainerBox>
    <div class="container-box-title d-flex">Salas de prova</div>
    <div class="d-flex flex-column" v-for="sala in salas" :key="sala.id">
      <div class="d-flex flex-row pointer">
        <div @click="expnd(sala)">
          <v-icon  v-text="sala.expand ? 'mdi-chevron-down' : 'mdi-chevron-right'" />
          <span class="title underline">{{ sala.title }}</span>
        </div>
        <a v-if="sala.expand" :href="sala.url" target="_blank" class="ml-2" title="Expandir"><v-icon v-text="'mdi-arrow-expand'" /></a>
      </div>
      <iframe v-if="sala.expand" :src="sala.url" class="h-full minh" />
    </div>
  </ContainerBox>
</template>

<script>
  import ContainerBox from '../components/ContainerBox.vue';

  export default {
    name: 'AlocacaoSalas',
    components: {
      ContainerBox,
    },
    data: () => ({
      salas: [{
        id: 1,
        url: "https://concursoapi.pge.se.gov.br/OfficialDocuments/Sala1B.pdf",
        title: "SALA 1B",
        expand: false
      },{
        id: 2,
        url: "https://concursoapi.pge.se.gov.br/OfficialDocuments/Sala1C.pdf",
        title: "SALA 1C",
        expand: false
      },{
        id: 3,
        url: "https://concursoapi.pge.se.gov.br/OfficialDocuments/Sala1D.pdf",
        title: "SALA 1D",
        expand: false
      },{
        id: 4,
        url: "https://concursoapi.pge.se.gov.br/OfficialDocuments/SalaEstudo.pdf",
        title: "SALA DE ESTUDO EM GRUPO",
        expand: false
      }],
    }),
    methods: {
      expnd(sala) {
        this.salas.map(e => e.id !== sala.id ? e.expand = false : true);
        sala.expand = !sala.expand;
      },
    },
    computed: {
    },
  }
</script>

<style>
  .minh {
    min-height: 60vh;
    margin-bottom: 8px;
    margin-left: 24px;
  }
</style>
