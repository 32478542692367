<!-- eslint-disable vue/multi-word-component-names -->
<!-- eslint-disable vue/valid-v-for -->
<template>
     <ContainerBox>
        <div class="container-box-title">Concursos Em Andamento</div>
        <div class="w-full h-[100vh] flex flex-wrap gap-5 items-start">
            <span class="font-bold text-2xl" v-if="concursos.length == 0">Não há nenhum concurso em andamento no momento </span>
            <v-card outlined class="contest-card lrounded rounded-lg mr-3 my-3" width="450" v-for="(card, index) in concursos">
                <v-card-title class="text-2xl font-semibold">{{card.name}}</v-card-title>
                <v-card-text class="text-lg black--text">Encerramento em {{ card.contestFinalDate }}</v-card-text>
                <v-card-actions class="ml-4 pr-4 pb-4 gap-2 m-auto sm:gap-1 sm:flex-col">
                    <div @click="$router.push({path: `/concurso/${card.id}` }).catch(()=>{});">
                    <CustomButton color="#1570B2" icon="mdi-plus-circle" texto="Mais informações"/>
                    </div>
                    <div @click="card.dialog = true, idContest = card.id, idIndex = index">
                    <EnrollModal v-if="!isAdmin" @desenroll="desenrollContest(idIndex, card)" @enroll="enrollContest(idContest, idIndex, card)"
                        @enrolled="card.status = 'Pagamento_Pendente'"
                        @sendedPaymentProof="card.status = 'Pagamento_Em_Analise'"
                        @disableDialog="card.dialog = false"
                        @disableDialogRegistration="card.dialogRegistration = false"
                        :inscriptionId="inscriptionId"
                        :isAuth="isAuth"
                        :status="card.status"
                        :dialog="card.dialog"
                        :dialogRegistration="card.dialogRegistration"
                        :inscriptionInitialDate="card.contestStartDate"
                        :inscriptionFinalDate="card.inscriptionsFinalDate"
                        :isEnroll="card.isEnroll"
                        :isAdmin="isAdmin"/>
                    </div>
                    <div @click="$router.push({path: `restrictedArea/${card.id}`}).catch(()=>{});" v-if="isAdmin">
                    <CustomButton color="#17A536" icon="mdi-cog" texto="Gerenciar" />
                    </div>
                </v-card-actions>
                </v-card>
        </div>
    </ContainerBox>
</template>

<script>
    import ContainerBox from '@/components/ContainerBox.vue';
    import CustomButton from '@/components/CustomButton.vue';
    import EnrollModal from '@/components/EnrollModal.vue';
    export default {
        components: {
            ContainerBox,
            CustomButton,
            EnrollModal,
        },
        data: () => ({
        dialog: false,
        inscriptionId: '',
        qrcode: '00020126600014br.gov.bcb.pix0114348412580001320220CONCURSO ESTÁGIO PGE520400005303986540520.005802BR5924PROCURADORIA GERAL DO ES6007Aracaju62240520BES189c096c61028faa363047A46',
        isCopy: false,
        idContest: '',
        teste1: true,
        isEnroll: false,
        dialogRegistration: false,
        isAdmin: false,
        isAuth: false,
        idUser: '',
        idIndex: '',
        concursos: [],
        isEnrollValueAux: '',
        statusAux: ''
    }),
    methods: {
      async enrollContest(contest, index, card){
        card.dialog = false
        var idCandidate = this.$store.getters.getId
        var response = await this.$api.InscriptionTo(contest, idCandidate).catch(console.error)
        if(response.status == 200 || response.status == 201){

          sessionStorage.setItem('idInscription', response.data.inscription)
          card.dialogRegistration = true
          this.concursos[index].isEnroll = true
          card.isEnroll = true
        }
        else{
          this.$swal('Erro', 'Ocorreu um erro durante a inscrição no concurso', 'error')
        }
        
        this.dialog = false
      },
      expnd(concurso) {
        let index = this.expand.findIndex(x => x === concurso.id);
        if (index >= 0) this.expand.splice(index, 1);
        else this.expand.push(concurso.id);
      },
      async desenrollContest(index, card){
          await this.$api.CancelInscription(this.concursos[index].id, this.idUser).then( (response) => {
              if(response.status == 204){
                  this.$swal('Sucesso', `Inscrição foi cancelada`,'success')
                  this.concursos[index].isEnroll = false,
                  card.dialog = false
              }
              else{
                  this.$swal('Erro', `Ocorreu um erro ao cancelar a inscrição`,'error')
                  card.dialog = false
              }
          })
      },
      
      async loadContests() {
        var response = await this.$api.getContestsActives().catch(console.error);
        var auxReponse = response.data
        console.log(auxReponse)
        if(!this.isAuth){
          for(var i = 0; i < auxReponse.length; i++){
            const aux = {
              id: auxReponse[i].id,
              inscriptionsFinalDate: auxReponse[i].inscriptionsFinalDate,
              contestFinalDate: auxReponse[i].contestFinalDate,
              contestStartDate: auxReponse[i].contestStartDate,
              name: auxReponse[i].name,
              isEnroll: false,
              dialog: false
            };
            this.concursos.push(aux)
          }
        }
        else{
          for(var j = 0; j < auxReponse.length; j++){
            var responseInscription = await this.$api.VerifyInscription(auxReponse[j].id, this.idUser).catch(console.error)
            if(responseInscription.status == 200){
              this.isEnrollValueAux = responseInscription.data
              var statusResponse = await this.$api.VerifyInscriptionByCandidate(auxReponse[j].id, this.idUser).catch(console.error)
              if(statusResponse.status == 200){

                this.statusAux = statusResponse.data
              }
            }
            else{
              this.isEnrollValueAux = false
            }
            const aux = {
              id: auxReponse[j].id,
              inscriptionsFinalDate: auxReponse[j].inscriptionsFinalDate,
              contestFinalDate: auxReponse[j].contestFinalDate,
              contestStartDate: auxReponse[j].contestStartDate,
              name: auxReponse[j].name,
              isEnroll: this.isEnrollValueAux,
              status: this.statusAux,
              dialog: false,
              dialogRegistration: false
            };
          this.concursos.push(aux)
          }
        }
      },
      checkDate(concurso) {
        var now = Date.now();
        return (now >= new Date(concurso.contestStartDate) && now <= new Date(concurso.contestFinalDate));
      },
    },
    computed: {
      getConcursos: function () {
        return this.concursos;
      },
      checkExpand() {
        return this.expand;
      },
    },
    mounted() {
      this.isAdmin = this.$store.getters.role
      this.isAuth = this.$store.getters.isLoggedIn
      this.idUser = this.$store.getters.getId
      this.loadContests();
    },
    }
</script>