<template>
    <div class="flex flex-col justify-center items-center">
        <v-icon size="100" color="#0263B0">{{icon}}</v-icon>
        <div class="flex flex-col text-lg items-center" style="color: #0263B0;">
            <span class="font-bold">{{text}}</span>
            <span>{{valueCard}}</span>
        </div>
    </div>
</template>

<script>
    export default{
        props: ['icon', 'text', 'valueCard']
    }
</script>