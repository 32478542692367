<template>
  <ContainerBox>
    <div class="container-box-title d-flex">Provas e Gabaritos</div>
    <div class="d-flex flex-column" v-for="prova in provas" :key="prova.id">
      <div v-if="prova.enable" class="d-flex flex-row pointer">
        <div @click="expndProva(prova)">
          <v-icon  v-text="prova.expand ? 'mdi-chevron-down' : 'mdi-chevron-right'" />
          <span class="title underline">{{ prova.title }}</span>
        </div>
        <a v-if="prova.expand" :href="prova.url" target="_blank" class="ml-2" title="Expandir"><v-icon v-text="'mdi-arrow-expand'" /></a>
      </div>
      <iframe v-if="prova.expand" :src="prova.url" class="h-full minh" />
    </div>
    <div class="d-flex flex-column" v-for="gabarito in gabaritos" :key="gabarito.id">
      <div v-if="gabarito.enable" class="d-flex flex-row pointer">
        <div @click="expndGabarito(gabarito)">
          <v-icon  v-text="gabarito.expand ? 'mdi-chevron-down' : 'mdi-chevron-right'" />
          <span class="title underline">{{ gabarito.title }}</span>
        </div>
        <a v-if="gabarito.expand" :href="gabarito.url" target="_blank" class="ml-2" title="Expandir"><v-icon v-text="'mdi-arrow-expand'" /></a>
      </div>
      <iframe v-if="gabarito.expand" :src="gabarito.url" class="h-full minh" />
    </div>
  </ContainerBox>
</template>

<script>
  import ContainerBox from '../components/ContainerBox.vue';

  export default {
    name: 'ProvaGabarito',
    components: {
      ContainerBox,
    },
    data: () => ({
      provas: [{
        id: 1,
        url: "https://concursoapi.pge.se.gov.br/OfficialDocuments/Prova2023.pdf",
        title: "Prova Objetiva",
        expand: false,
        enable: true
      }],
      gabaritos: [{
        id: 1,
        url: "https://concursoapi.pge.se.gov.br/OfficialDocuments/Edital-005.2023.pdf",
        title: "Gabarito Definitivo e Relação Provisória dos candidatos habilitados à correção da prova subjetiva",
        expand: false,
        enable: true
      }]
    }),
    methods: {
      expndProva(prova) {
        this.provas.map(e => e.id !== prova.id ? e.expand = false : true);
        prova.expand = !prova.expand;
      },
      expndGabarito(prova) {
        this.provas.map(e => e.id !== prova.id ? e.expand = false : true);
        prova.expand = !prova.expand;
      },
    },
    computed: {
    },
  }
</script>

<style>
  .minh {
    min-height: 60vh;
    margin-bottom: 8px;
    margin-left: 24px;
  }
</style>
