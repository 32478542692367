<template>
  <div>
    <!-- Menu mobile -->
    <v-navigation-drawer
      height="100vh"
      class="drawer"
      v-model="drawer"
      absolute
      app
      left
      temporary
    >
      <div class="header">
        <router-link :to="{ name: 'HomePage' }">
          <v-img src="@/assets/icone-5.png" class="img-header" />
        </router-link>
        <h6 class="drawer-title">Sistema de Gestão de Procuradoria</h6>
      </div>
      <hr />
      <v-list>
        <div v-for="(nav, index) in filteredNav" :key="index">
          <v-list-group
            v-if="nav.children && nav.children.length > 0"
            v-model="nav.active"
          >
            <template v-slot:activator>
              <v-list-item-icon
                ><v-icon class="drawer-icon" v-text="nav.icon"></v-icon
              ></v-list-item-icon>
              <v-list-item-title v-text="nav.title == null ? 'Usuário ' : nav.title"></v-list-item-title>
            </template>
            <v-list-item
              v-for="(child, index2) in filteredNavAdmin(nav.children)"
              :key="index2"
              @click="handleItem(child.type,[child.route, child.title])"
            >
              <v-list-item-content>
                <v-list-item-title v-text="child.title"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
          <v-list-item v-else @click="goTo(nav.route, nav.title)">
            <v-list-item-icon
              ><v-icon class="drawer-icon" v-text="nav.icon"></v-icon
            ></v-list-item-icon>
            <v-list-item-title v-text="nav.title"></v-list-item-title>
          </v-list-item>
        </div>
      </v-list>
      <v-spacer></v-spacer>
      <hr />
      <v-footer>
        <small
          >Sistema desenvolvido pela CODIN<br />
          SGP © 2010 - 2023<br/>
          <a href="mailto:desenvolvimento@pge.se.gov.br"
            >desenvolvimento@pge.se.gov.br</a
          ><br />
          Versão 1.0.0
        </small>
      </v-footer>
    </v-navigation-drawer>
    <!-- End menu mobile -->
    <!-- Menu -->
    <div class="container-header container">
      <router-link :to="{ name: 'HomePage' }">
        <v-img src="@/assets/icone-5.png" class="img-header" />
      </router-link>
      <v-icon
        class="img-menu hidden-md-and-up"
        large
        color="primary"
        @click.stop="drawer = !drawer"
        v-text="drawer ? 'mdi-backburger' : 'mdi-menu'"
      ></v-icon>
      <nav class="hidden-sm-and-down">
        <ul class="container-menu">
          <li class="menu" v-for="(nav, index) in filteredNav" :key="index">
            <div v-if="nav.children && nav.children.length > 0">
              <v-menu
                class="card-menu"
                :close-on-click="closeOnClick"
                offset-y
                transition="slide-y-transition"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-card v-if="!nav.isEspecial"
                    color="bg-gray-50"
                    flat
                    class="card-menu hidden-md-and-down"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon v-text="nav.icon"></v-icon>
                    <v-card-text v-text="nav.title"></v-card-text>
                  </v-card>
                  <v-btn text x-large v-bind="attrs"
                    v-on="on" v-else><v-icon size="40">{{ nav.icon }}</v-icon></v-btn>
                </template>
                <v-list>
                  <v-list-item
                    v-for="(child, index2) in filteredNavAdmin(nav.children)"
                    :key="index2"
                    link
                    @click="handleItem(child.type,[child.route, child.title])"
                  >
                    <v-list-item-title v-text="child.title"></v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
            <div v-else>
              <v-card
                color="white"
                flat
                @click="goTo(nav.route, nav.title)"
                class="card-menu hidden-md-and-down"
              >
                <v-icon v-text="nav.icon"></v-icon>
                <v-card-text v-text="nav.title"></v-card-text>
              </v-card>
            </div>
          </li>
          <li>
            <v-menu
              :close-on-click="closeOnClick"
              offset-y
              transition="slide-y-transition"
            >
            </v-menu>
          </li>
        </ul>
      </nav>
    </div>
  </div>
 
</template>

<script>
export default {
    /* eslint-disable */
  name: "Navbar",
  data() {
    return {
      drawer: false,
      closeOnClick: true,
      id: "",
      navs: [
        {
          title: "Concursos",
          icon: "mdi-file-document-edit",
          free: true,
          active: false,
          route: "ProvaGabarito",
          children: [{
            title: "Abertos",
            route: "HomePage",
            type: 0,
            free: true
          },
          {
            title: "Em andamento",
            route: "ConcursosAndamentos",
            type: 0,
            free: true
          },{
            title: "Encerrados",
            route: "ConcursosEncerrados",
            type: 0,
            free: true }
          ]
        },
        {
          title: "Cadastre-se",
          icon: "mdi-account-plus",
          isAuth: false,
          justDeslogged: true,
          active: false,
          route: "Cadastro",
        },
        {
          title: "Login",
          icon: "mdi-login",
          justDeslogged: true,
          active: false,
          route: "Login"
        },
        ,
        {
          icon: "mdi-account-circle",
          justLogged: true,
          active: false,
          isEspecial: true,
          children: [{
            title: "Meus dados",
            route: "HomePage",
            free: true,
            type: 0
          },
          {
            title: "Minhas inscrições",
            route: "MinhasInscricoes",
            isAdmin: false,
            type: 0
          },{
            title: "Gerenciar Concursos",
            route: "AdminConcursos",
            isAdmin: true,
            type: 0
          },{
            title: "Sair",
            free: true,
            type: 1
              
          }]
        }
      ],
    };
  },
  methods: {
    goTo(route, name) {
      if (name !== "any") this.$router.push({name: route}).catch(()=>{});
      else window.open("https://concursoapi.pge.se.gov.br/OfficialDocuments/Edital-001.2023.pdf", "_blank")//window.location.href = "https://concursoapi.pge.se.gov.br/OfficialDocuments/Edital-001.2023.pdf";
    },
    logout(){
      sessionStorage.clear()
      
      this.$store.state.isAuth = false
      this.$store.state.isAdmin = false
      this.$store.state.id = undefined
      this.$store.state.idInscription = undefined
      
      this.$router.push({name: 'Login'})
    },
    handleItem(type, object){
      if(type == 0){
        this.goTo(object[0], object[1])
      }
      else{
        this.logout()
      }
    },
    showNav (item) {
      const isLoggedIn = this.$store.getters.isLoggedIn;
      if(isLoggedIn){
        if(item.free){
          return item
        }
        if(item.justLogged){
          return item
        }
      }
      else{
        if(item.free){
          return item
        }
        if(item.justDeslogged){
          return item
        }
      }
  },
  filteredNavAdmin (value) {
      return value.filter(x => this.showNavAdmin(x))
    },
  showNavAdmin (item) {
      const isAdmin = this.$store.getters.role;
      if(isAdmin){
        if(item.free){
          return item
        }
        if(item.isAdmin){
          return item
        }
      }
      else{
        if(item.free){
          return item
        }
        if(!item.isAdmin){
          return item
        }
      }
  }
},
  computed: {
    filteredNav () {
      return this.navs.filter(x => this.showNav(x))
    },
    
  }
};
</script>

<style lang="scss">
@import "Navbar.scss";
</style>