<!-- eslint-disable vue/multi-word-component-names -->
<script>

export default {
    props: [
    'width',
    'dialog',
    'title',
    'titleIcon',
    'valueCard',
    'okLabel',
    'okIcon',
    'cancelLabel',
    'cancelIcon',
    'type'
],
data(){
  return{
    okButtonColorModal: "buttonPositive",
    detailsColorModal: "",
    borderClassModal:"",
    titleModal: "",
    titleIconModal: "",
    persistentModal: true
  }
},
mounted(){
  switch(this.type) {
    case "warning":
      this.okButtonColorModal = "buttonPositive"
      this.detailsColorModal = "yellow-accent-4"
      this.borderClassModal = "alert-border"
      this.titleModal = this.title || "Cuidado"
      this.titleIconModal = this.titleIcon || "mdi-information"
      this.persistentModal = true
      break
    case "danger":
      this.okButtonColorModal = "buttonNegative"
      this.detailsColorModal = "buttonNegative"
      this.borderClassModal = "negative-border"
      this.titleModal = this.title || "Perigo"
      this.titleIconModal = this.titleIcon || "mdi-alert"
      this.persistentModal = true
      break
    case "info":
      this.okButtonColorModal = "codin"
      this.detailsColorModal = "codin"
      this.borderClassModal = "codin-border"
      this.titleModal = this.title || "Informativo"
      this.titleIconModal = this.titleIcon || "mdi-information"
      this.persistentModal = false
      break
    case "confirmation":
      this.okButtonColorModal = "buttonPositive"
      this.detailsColorModal = "codin"
      this.borderClassModal = "codin-border"
      this.titleModal = this.title || "Confirmação"
      this.titleIconModal = this.titleIcon || "mdi-information"
      this.persistentModal = this.true
      break
  }
}
}
</script>
<!-- eslint-disable vue/no-mutating-props -->
<template>
  <v-dialog persistent v-model="dialog" :width="width">
      <v-card :class="'p-2 ' + borderClassModal">
          <v-card-title class="d-flex align-center justify-center">
          <v-icon v-if="titleIcon" :icon="titleIcon" :color="detailsColor" class="mr-2" size="large" />
           <span class="text-4xl font-bold"> {{ title }} </span>
          </v-card-title>
          <hr class="mt-2"/>
        <v-card-text>
          <slot name="texto" />
        </v-card-text>
        <v-card-actions class="d-flex gap-2 justify-end sm:flex-col">
          <slot name="botoes" />
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<style scoped>
.v-card.codin-border {
  border-left: 7px solid #0263b0 !important;
}

.v-card.negative-border {
  border-left: 7px solid red !important;
}

.v-card.alert-border {
  border-left: 7px solid #FFD600 !important;
}
</style>