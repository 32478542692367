<script>
import ContainerBox from '@/components/ContainerBox.vue'
import ContestDocumentForm from '@/components/ContestDocumentForm.vue'

import axios from "../services/axiosBase"
import baseURL from '@/services/baseUrl'

export default {
  components: {
    ContainerBox,
    ContestDocumentForm
  },
  data() {
    return {
      valid: true,
      concurso: [],
      url: baseURL,
      feedback: {
        show: false,
        text: "",
        type: "positive"
      },
      rules: {
        required: value => !!value || "Obrigatório",
      },
      newContest: {
        idConcurso: "",
        documents: [],
      }
    }
  },
  methods: {
    async loadContest(){
        this.idConcurso = this.$route.params.code
        var response = await this.$api.getContest(this.idConcurso)
        if(response.status != 200){
            this.$router.push({
            name: "NotFound"
        })
        }
        else{
            this.concurso = response.data
        }
    },
    async registerNewDocument() {
      this.$refs.form.validate()

      if (!this.valid) {
        this.feedback.show = true 
        this.feedback.text = "Um ou mais campos possuem erros."
        this.feedback.type = "negative"
      } else {
        const formData = new FormData()

        const documents = this.newContest.documents.map(doc => {
          return {
            Document: doc.file,
            DocumentType: doc.type
          }
        })

        documents.forEach((doc, index) => {
          
          formData.append(`documents[${index}].Document`, doc.Document)
          formData.append(`documents[${index}].DocumentType`, doc.DocumentType)
        })
        const url = `${this.url}Contest/AddDocumentsContest/${this.idConcurso}`

        const resp = await axios.post(url, formData)

        if(resp.status == 204){
            this.$swal('Sucesso', 'Os documentos foram anexados ao concurso', 'success')
            this.documents = []
        }
        else{
            this.$swal('Erro', 'Ocorreu um erro ao anexar os documentos ao concurso', 'error')
        }
      }
    },
    onAddedFileFromDocumentForms(documents) {
      this.newContest.documents = documents
    },
    cancelForm() {
      this.$router.push({path: `/restrictedArea/${this.idConcurso}`})
    }
  },
  async beforeMount(){
    await this.loadContest()
  }
}
</script>

<template>
  <ContainerBox class="container">
    <h1 class="text-4xl font-semibold">Anexar documento(s) ao {{ concurso.name }} </h1>

    <v-form class="w-full mt-12" ref="form" v-model="valid">
      <ContestDocumentForm 
        :concurso="concurso"
        v-on:documents="onAddedFileFromDocumentForms" 
        :rules="[rules.required]"
      />

      <section 
        class="w-full flex flex-wrap flex-md-nowrap justify-content-center mx-auto my-8"
      >
        <v-btn 
          large
          elevation="0"
          class="my-2 md:my-0 form-action-button mr-md-2" 
          color="error" 
          @click="cancelForm"
        >
          Cancelar
        </v-btn>
        <v-btn 
          large
          elevation="0"
          class="my-2 md:my-0 form-action-button ml-md-2" 
          @click="registerNewDocument" 
          color="primary"
          :disabled="newContest.documents.length > 0 ? false : true"
        >
          Salvar
        </v-btn>
      </section>
    </v-form>

    <v-snackbar 
      bottom
      v-model="feedback.show" 
      :color="feedback.type === 'positive' ? 'green' : 'red'"
      class="d-flex align-center"
    >
      <span class="text-body-1">{{ feedback.text }}</span>

      <template v-slot:action="{ attrs }">
        <v-btn icon v-bind="attrs" @click="feedback.show = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </ContainerBox>
</template>

<style scoped>
.form-action-button {
  width: 50%;
  max-width: 500px;
}
</style>