import Vue from 'vue'
import Vuex from 'vuex'
  
Vue.use(Vuex)
  
export default new Vuex.Store({
    state: {
        isAuth: sessionStorage.getItem('token') ? true : false,
        isAdmin: sessionStorage.getItem('role') ? (sessionStorage.getItem('role') == '1' ? true : false)  : false,
        id: sessionStorage.getItem('id'),
        idInscription: sessionStorage.getItem('idInscription'),
        etnia: sessionStorage.getItem('etnia')
    },
    getters: {
    isLoggedIn(state) {
        return state.isAuth;
    },
    role(state) {
        return state.isAdmin;
    },
    getId(state){
        return state.id;
    },
    getInscriptions(state){
        return state.idInscription
    },
    getEtnia(state){
        return state.etnia
    }
},
    mutations: {
        setInscriptionNumber(state, number){
            state.idInscription = number
        }
    }
})