import http from "@/services/axiosBase";

export function getHttp() {
	return http;
}

// http.interceptors.response.use(
// 	response => response,
// 	error => {
// 		return error
// 	}
//);

export async function CancelInscription(idContest, idCandidate){
	return http.patch(`/Inscription/CancelInscription/${idContest}/${idCandidate}`)
	.then( async(response) => {
		return response
	}).catch((error) => {
		return error
	})
}

export async function InsertPaymentProof(inscriptionNumber, attachment){
	const form = new FormData()
	form.append('document', attachment);
	return http.post(`/Inscription/InsertPaymentProof/${inscriptionNumber}`, form).then(async (response) => {
		return response
	}).catch((error) => {
		return error
	})
}

export async function VerifyEmailExist(email){
	return http.get(`/Candidate/VerifyEmailExist/${email}`)
	.then( async (response) => {
		return response
	}).catch((error) => {
		return error
	})
}

export async function VerifyCPFExist(cpf){
	return http.get(`/Candidate/VerifyCPFExist/${cpf}`)
	.then( async (response) => {
		return response
	}).catch((error) => {
		return error
	})
}

export async function VerifyCode(code){
	return http.get(`/Login/VerifyCode?code=${code}`)
	.then( async (response) => {
		return response
	}).catch((error) => {
		return error
	})
}

export async function AlterPassword(usuario){
	return http.patch(`/Login/AlterPassword?email=${usuario.email}&newPassword=${usuario.newPassword}&code=${usuario.code}`)
	.then( async (response) => {
		return response
	}).catch((error) => {
		return error
	})
}

export async function GetInscriptionStatus(idContest, status){
	return http.get(`/Inscription/GetByInscriptionConnection/${idContest}/${status}`)
	.then( async (response) => {
		return response
	}).catch((error) => {
		return error
	})
}

export async function recuperarSenha(email){
	return http.patch(`/Login/CreateCode?email=${email}`)
	.then( async (response) => {
		return response
	}).catch((error) => {
		return error
	})
}

export async function inserirEtnia(idCandidato, etnia){
	return http.patch(`/Candidate/CandidateEthnicity?idCandidate=${idCandidato}&etnia=${etnia}`)
	.then( async (response) => {
		return response
	}).catch((error) => {
		return error
	})
}

export async function dropdownEtnia(){
	return http.get('/Info/EthnicityDropDown').then(
		async (response) => {
			return response
		})
		.catch((error) => {
			return error
		})
}

export async function GetInscriptionsByStatus(idContest, status){
	return http.get(`/Inscription/GetInscriptionsByStatus/${idContest}/${status}`)
	.then( async (response) => {
		return response
	}).catch((error) => {
		return error
	})
}
export async function VerifyUserExist(user){
	return http.get(`/Candidate/VerifyLoginExist/${user}`)
	.then( async (response) => {
		return response
	}).catch((error) => {
		return error
	})
}

export async function VerifyInscriptionByCandidate(idContest, idCandidate){
	return http.get(`/Inscription/VerifyInscriptionStatusById?contestId=${idContest}&candidateId=${idCandidate}`)
	.then(async (response) => {
		return response
	}).catch((error) => {
		return error
	})
}

export async function VerifyInscription(idContest, idCandidate){
	return http.get(`/Inscription/VerifyInscriptionExist/${idContest}/${idCandidate}`)
	.then(async (response) => {
		return response
	}).catch((error) => {
		return error
	})
}

export async function MyInscriptions(idCandidate){
	return http.get(`/Inscription/GetInscriptionsByCandidate/${idCandidate}`)
	.then(async (response) => {
		return response
	}).catch((error) => {
		return error
	})
}

export async function InscriptionTo(idContest, idCandidate){
	return http.post(`/Inscription/InscriptionContest/${idContest}/${idCandidate}`)
	.then(async (response) => {
		return response
	}).catch((error) => {
		console.log(error)
	})
}

export async function register(credentials){
	return http.post("/Candidate/UserCandidate", credentials)
	.then(async (response) => {
		return response
	}).catch((error) => {
		console.log(error)
	})
}

export async function login(credentials){
	return http.post("/Login/Authentication", credentials)
	.then(async (response) => {
		return response
	})
	.catch((error) => {
		return error;
	})
}

export async function getContestsActives(){
	return http.get("Contest/GetActives")
	.then(async (response) => {
		return response;
	})
	.catch((error) => {
		throw error;
	});
}

export async function getContestsWithEnrollOpen(){
	return http.get("Contest/GetOpenInscription")
	.then(async (response) => {
		return response;
	})
	.catch((error) => {
		throw error;
	});
}

export async function getContestsRunning(){
	return http.get("Contest/GetActives")
	.then(async (response) => {
		return response;
	})
	.catch((error) => {
		throw error;
	});
}

export async function getContestsDisabled(){
	return http.get("Contest/GetDisabled")
	.then(async (response) => {
		return response;
	})
	.catch((error) => {
		throw error;
	});
}

export async function getContest(id) {
	return http.get(`Contest/GetById/${id}`)
		.then((response) => {
			return response;
		})
		.catch((error) => {
			return error;
		});
}
export async function getAllCandidates() {
	return http.get("Candidate/GetAll")
		.then(async (response) => {
			return response;
		})
		.catch((error) => {
			throw error;
		});
}

export async function registerCandidate(candidateInfo) {
	return http.post("Candidate/CandidateInscription", candidateInfo)
		.then((response) => {
			return response;
		})
		.catch((error) => {
			throw error;
		});
}

export async function verifyCode(code) {
	return http.get(`Info/VerifyAccessCode?accessCode=${code}`)
		.then((response) => {
			return response;
		})
		.catch((error) => {
			throw error;
		});
}

export async function getEnums() {
	return http.get(`Info/getAllDropdowns`)
		.then((response) => {
			return response;
		})
		.catch((error) => {
			throw error;
		});
}

export async function confirmInscription(cpf, idContest) {
	return http.patch(`Candidate/ConfirmInscription?cpf=${cpf}&idContest=${idContest}`)
		.then(response => {
			return response;
		})
		.catch(error => {
			throw error
		});
}

export async function revokeInscription(cpf, idContest) {
	return http.patch(`Candidate/RevokeInscription?cpf=${cpf}&idContest=${idContest}`)
		.then(response => {
			return response;
		})
		.catch(error => {
			throw error
		});
}

export async function verifyInscriptionStatus(cpf, id) {
	return http.get(`Inscription/verifyInscriptionStatus?contestId=${id}&cpf=${cpf}`)
		.then(response => {
			return response;
		})
		.catch(error => {
			throw error
		});
}

export async function verifyInscriptionCode(hash) {
	return http.get(`Inscription/IsInscriptionConfirmed?inscriptionHash=${hash}`)
		.then(response => {
			return response;
		})
		.catch(error => {
			throw error
		});
}

export async function getInscriptions() {
	return http.get(`Inscription/getAll`)
		.then(response => {
			return response;
		})
		.catch(error => {
			throw error
		});
}

export async function getContests() {
	const urls = ['/Contest/GetActives', '/Contest/GetDisabled']	

	const activeContestsRes = await http.get(urls[0])
	const disabledContestsRes = await http.get(urls[1])

	const activeContests = activeContestsRes.data.map(contest => ({
		...contest,
		active: true
	}))

	const disabledContests = disabledContestsRes.data.map(contest => ({
		...contest,
		active: false
	}))

	return [].concat(activeContests).concat(disabledContests)
}