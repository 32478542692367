<template>
  <ContainerBox>
    <div class="container-box-title d-flex">Página não encontrada.</div>
    <br />
    <div class="notfound">404</div>
  </ContainerBox>
</template>

<script>
  import ContainerBox from '../components/ContainerBox.vue';

  export default {
    name: 'NotFound',
    components: {
      ContainerBox,
    },
    data: () => ({
    }),
  }
</script>

<style>

  @media only screen and (min-width: 400px) {
    .notfound {
      font-size: 14rem !important;
    }
  }
  .notfound {
    font-size: 10rem;
    color: #0263b0;
    -webkit-text-stroke-color: #0263b0;
    -webkit-text-stroke-width: 0.6rem;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
</style>
