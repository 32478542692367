<!-- eslint-disable vue/no-mutating-props -->
<template>
    <div>
        <CustomButton class="w-full" v-if="(status == null || status == 'Cancelada' || !isEnroll) && isEnrollOpen() && !isAdmin" color="#17A536" icon="mdi-pen" texto="Inscrever-se" />
        <CustomButton class="w-full" v-if="isEnroll && !isAdmin  && isEnrollOpen() && status == 'Pagamento_Pendente'" color="#A52017" icon="mdi-pen-off" texto="Cancelar Inscrição" />
        <Modal v-if="!isAuth" title="Você não está com uma sessão ativa!" type="warning" width="750" @confirm-Modal="dialog = false" @disable-Modal="dialog = false" :dialog="dialog" v-model="dialog">
            <template v-slot:texto>
                <h2 class="text-black font-bold text-xl pt-2 text-center">Você será redirecionado para a tela de login para iniciar a sua sessão</h2>
            </template>
        
            <template v-slot:botoes>
                <div @click="$router.push('/login')">
                    <CustomButton color="#17A536" icon="mdi-check-circle" texto="Confirmar" />
                </div>
            </template>
        </Modal>
        <Modal v-if="(status == null || status == 'Cancelada' || !isEnroll) && isEnrollOpen() && !isAdmin && isAuth" title="Deseja se inscrever no concurso?" type="warning" width="900" @confirm-Modal="dialog = false; dialogRegistration = true" @disable-Modal="dialog = false" :dialog="dialog" v-model="dialog">
            <template v-slot:botoes>
                <div @click="$emit('disableDialog')">
                    <CustomButton color="#7D7F80" icon="mdi-close-circle" texto="Cancelar" />
                </div>
                <div @click="dialog = false, $emit('enroll')">
                    <CustomButton color="#17A536" icon="mdi-check-circle" texto="Confirmar" />
                </div>
            </template>
        </Modal>
        <Modal v-if="isEnroll && !isAdmin && isEnrollOpen() && status == 'Pagamento_Pendente'" title="Deseja cancelar a sua inscrição neste concurso?" type="warning" width="900" :dialog="dialog" v-model="dialog">
        <template v-slot:botoes>
            <div @click="$emit('disableDialog')">
                <CustomButton color="#7D7F80" icon="mdi-close-circle" texto="Cancelar" />
            </div>
            <div @click="$emit('desenroll')">
                <CustomButton color="#17A536" icon="mdi-check-circle" texto="Confirmar" />
            </div>
            </template>
        </Modal>
        <Modal title="Inscrição Pendente" type="warning" width="750" @disable-Modal="dialogRegistration = false" :dialog="dialogRegistration" v-model="dialogRegistration">
        <template v-slot:texto>
            <div class="flex flex-col items-center gap-4">
            <h2 class="colorText font-bold pt-2 text-center">ESTA INSCRIÇÃO SOMENTE SERÁ VALIDADA MEDIANTE A REALIZAÇÃO DO PAGAMENTO DO PIX E ENVIO DO COMPROVANTE.
            CASO DESEJE, VOCÊ PODERÁ ACESSAR O PIX E A OPÇÃO DE ANEXÁ-LO, ACESSANDO “MINHAS INSCRIÇÕES” E CLICANDO NA RESPECTIVA INSCRIÇÃO.</h2>
            <img class=" w-1/2 mt-4" src="../assets/Qrcode.png" alt="qrcode">
            <span>Nome: PROCURADORIA GERAL DO ESTADO </span>
            <v-btn @click="copyQRCode()" color="primary" class="flex gap-2">
                Copiar código do QR Code
                <v-icon>
                mdi-content-copy
                </v-icon>
            </v-btn>
            <span v-if="isCopy" class=" text-positive">O código foi copiado!</span>
            <div class="w-2/4 h-32 flex flex-col">
                <v-file-input
                :rules="[validateFile(attachment)]"
                v-model="attachment"
                append-icon="mdi-paperclip"
                hint="Formatos permitidos: .pdf, .png, .jpeg"
                loader-height
                clearable
                filled 
                show-size
                prepend-icon=""
                @click:clear="clearAttachment"
                label="Comprovante do Pix">
                </v-file-input>
            </div>
            </div>
        </template>
        <template v-slot:botoes>
        <div @click="$emit('disableDialogRegistration'), $emit('enrolled')">
            <CustomButton color="#7D7F80" icon="mdi-clock-outline" texto="Deixar para depois" />
        </div>
        <div :class="validateAttachment(attachment) ? '' : 'pointer-events-none'" @click="$emit('disableDialogRegistration'), insertPaymentProof(attachment)">
            <CustomButton :color="validateAttachment(attachment) ? '#17A536': '#86BD92'" icon="mdi-send-variant-outline" :isdisabled="false" texto="Enviar comprovante" />
        </div>
        </template>
        </Modal>
    </div>
</template>

<script>
    import CustomButton from './CustomButton.vue';
    import Modal from './Modal.vue';
    export default{
        props: ['dialog', 'isEnroll','isAdmin', 'inscriptionId', 'isAuth','dialogRegistration', 'status', 'inscriptionInitialDate', 'inscriptionFinalDate'],
        data() {
            return {
                attachment: '',
                qrcode: '00020126820014br.gov.bcb.pix01364ceea742-e33b-4ba3-8694-b2dfcea5b88f0220Concurso Estagiarios520400005303986540520.005802BR5925PROCURADORIA GERAL DO EST6007ARACAJU62290525zTggJ2tJuVm8KELmMjwZqetWx6304C420',
                isCopy: false,
                date: '',
                statusAux: this.status
            };
        },
        methods: {
            clearAttachment(){
                this.attachment = new File()
            },
            copyQRCode(){
                navigator.clipboard.writeText(this.qrcode)
                this.isCopy = true
                setTimeout( () => {
                    this.isCopy = false
                }, 3000)
                
            },
            async insertPaymentProof(attachment){
                var response = await this.$api.InsertPaymentProof(sessionStorage.getItem('idInscription'), attachment).catch(console.error)
                if(response.status == 200){
                    this.$swal('Sucesso', `Comprovante enviado com sucesso, você poderá acompanhar o status da sua inscrição na aba "Minhas inscrições"`, 'success')
                    this.$emit('sendedPaymentProof')
                }
                else{
                    this.$swal('Erro', 'Ocorreu um erro ao enviar o comprovante', 'error')
                }
            },
            isEnrollOpen(){
                if(this.date == ''){
                    this.date = new Date()
                }
                var datePartInitial = this.inscriptionInitialDate.split('/')
                var dateConvertedInitial = new Date(datePartInitial[2], datePartInitial[1] - 1, datePartInitial[0])
                var datePartFinal = this.inscriptionFinalDate.split('/')
                var dateConvertedFinal = new Date(datePartFinal[2], datePartFinal[1] - 1, datePartFinal[0])
                dateConvertedInitial.setHours(0, 0, 0, 0)
                dateConvertedFinal.setHours(23, 59, 59, 59)
                return this.date >= dateConvertedInitial && this.date <= dateConvertedFinal
            },
            validateAttachment: (v) => (v.type === 'application/pdf' || v.type === "image/png" || v.type === "image/jpeg" ) && v != '' && v != null,
            validateFile: (v) => (v.type === 'application/pdf' || v.type === "image/png" || v.type === "image/jpeg" ) || "Somente arquivos com formato pdf, png ou jpeg são permitidos",
        },
        components: { CustomButton, Modal },
        beforeCreate(){
            this.date = new Date()
        }
    }
</script>

<style>
    .colorText{
        color: #D50000 !important;
        font: bold;
    }
</style>